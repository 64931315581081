import sprintf from "sprintf";
import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SAMPLE_ITEMS_URL = `${API_ROOT_URL}/sample_items`;
const SAMPLE_ITEM_URL = `${API_ROOT_URL}/sample_items/%s`;
const PUBLIC_SAMPLE_ITEMS_URL = `${API_ROOT_URL}/public/sample_items`;

export const getSampleItems = (request) =>
  api.getStream(SAMPLE_ITEMS_URL, { params: request.getDefinedValues() });
export const getSampleItemsForSelect = () => api.get(SAMPLE_ITEMS_URL);

export const getPublicSampleItems = (request) =>
  api.getStream(PUBLIC_SAMPLE_ITEMS_URL, {
    params: request.getDefinedValues(),
  });

export const getSampleItem = (id: number) =>
  api.getStream(sprintf(SAMPLE_ITEM_URL, id));

export const saveSampleItem = (body) => api.post(SAMPLE_ITEMS_URL, { body });

export const deleteSampleItem = (id: number) =>
  api.delete(sprintf(SAMPLE_ITEM_URL, id));

const SAVE_SAMPLE_ITEM_SETTINGS_URL = `${API_ROOT_URL}/sample-item-template`;
const SAMPLE_ITEMS_SETTINGS_URL = `${API_ROOT_URL}/sample-item-templates`;
const SAMPLE_ITEM_SETTINGS_URL = `${API_ROOT_URL}/sample-item-template/%s`;

export const getSampleItemsSettings = (request) =>
  api.getStream(SAMPLE_ITEMS_SETTINGS_URL, {
    params: request.getDefinedValues(),
  });

export const getSampleItemSettings = (id: number) =>
  api.getStream(sprintf(SAMPLE_ITEM_SETTINGS_URL, id));

export const saveSampleItemSettings = (body) =>
  api.post(SAVE_SAMPLE_ITEM_SETTINGS_URL, { body });
export const updateSampleItemSettings = (body) =>
  api.put(SAVE_SAMPLE_ITEM_SETTINGS_URL, { body });

export const deleteSampleItemSettings = (id: number) =>
  api.delete(sprintf(SAMPLE_ITEM_SETTINGS_URL, id));
