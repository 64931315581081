import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  useSheet({ content: { maxWidth: "320px" } }),
  reduxForm({ form: "DeleteOrderDialog" }),
);

DeleteOrderDialog.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func,
};

function DeleteOrderDialog(props) {
  return (
    <Dialog
      open={true}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      PaperProps={{
        style: {
          maxWidth: "320px",
        },
      }}
    >
      <DialogContent>
        <PageLoading isLoading={props.submitting} />
        <form autoComplete="off">
          <span
            style={{
              color: "gray",
            }}
          >
            {props.getLocalisationMessage(
              "are_you_sure_you_want_to_remove_order",
              "Are you sure you want to remove order?",
            )}
          </span>
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {props.getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {props.getLocalisationMessage("remove", "Remove")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(DeleteOrderDialog);
