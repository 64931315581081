import { fromJS } from "immutable";
import { ObservableCache } from "../../helpers/loading-cache";
import { CASH, CREDIT_BALANCE } from "../../constants/OrderPaymentTypes";

const WEIGHT_OPTIONS = "w";
const NOT_AVAILABLE_OPTIONS = "d";
const PAYER_OPTIONS = "p";
const SERVICE_TYPE_OPTIONS = "s";
const PAYMENT_TYPE_OPTIONS = "pt";

const optionsCache = new ObservableCache({
  loader: key => {
    switch (key) {
      case WEIGHT_OPTIONS:
        return Promise.resolve(
          fromJS([
            "2kg Document",
            "3kg parcel",
            "5kg parcel",
            "10 kg parcel",
            "30kg parcel",
            "100kg parcel",
            "0.5KG",
            "1KG",
            "1.5KG",
            "2KG",
            "2.5KG",
            "3KG",
            "3.5KG",
            "4KG",
            "4.5KG",
            "5KG",
            "5.5KG",
            "6KG",
            "6.5KG",
            "7KG",
            "7.5KG",
            "8KG",
            "8.5KG",
            "9KG",
            "9.5KG",
            "10KG",
          ]),
        );
      case PAYMENT_TYPE_OPTIONS:
        return Promise.resolve(fromJS([CASH, CREDIT_BALANCE]));
      case NOT_AVAILABLE_OPTIONS:
        return Promise.resolve(
          fromJS([
            "Don't Deliver until available",
            "Leave at the Door",
            "Leave with the Concierge",
          ]),
        );
      case PAYER_OPTIONS:
        return Promise.resolve(fromJS(["Sender", "Recipient"]));
      case SERVICE_TYPE_OPTIONS:
        return Promise.resolve(
          fromJS(["Saver", "Urgent", "Next Day", "Same Day"]),
        );
      default:
        return Promise.resolve(null);
    }
  },
});

export const getDraftOrderPayerOptions = () => optionsCache.get(PAYER_OPTIONS);
export const getDraftOrderWeightOptions = () =>
  optionsCache.get(WEIGHT_OPTIONS);
export const getDraftOrderPaymentTypeOptions = () =>
  optionsCache.get(PAYMENT_TYPE_OPTIONS);
export const getDraftOrderServiceTypeOptions = () =>
  optionsCache.get(SERVICE_TYPE_OPTIONS);
export const getDraftOrderNotAvailableOptions = () =>
  optionsCache.get(NOT_AVAILABLE_OPTIONS);
