import { api } from "../shared/BaseApi";
import { API_V2_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CUSTOMER_PAYMENT_METHODS_V2_URL = `${
  API_V2_ROOT_URL
}/customer/payment_methods`;
const CUSTOMER_MOST_USED_ADDRESSES_V2_URL = `${
  API_V2_ROOT_URL
}/customer/most_used_addresses`;
const CUSTOMER_PACKAGES_PRICES_STARTING_FROM_URL = `${
  API_V2_ROOT_URL
}/customer/packages/prices/starting_from`;
const PACKAGES_NSA_URL = `${API_V2_ROOT_URL}/packages/nsa`;

const LOCATION_DETAILS_URL = `${API_V2_ROOT_URL}/location/details`;

const PUBLIC_LOCATION_DETAILS_URL = `${
  API_V2_ROOT_URL
}/public/location/details`;

export const getPaymentMethods = request =>
  api.getStream(CUSTOMER_PAYMENT_METHODS_V2_URL, {
    params: request.getDefinedValues(),
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getCustomerPackagePricesStaringFrom = request =>
  api.getStream(CUSTOMER_PACKAGES_PRICES_STARTING_FROM_URL, {
    params: request.getDefinedValues(),
  });

export const getMostUsedAddresses = () =>
  api.getStream(CUSTOMER_MOST_USED_ADDRESSES_V2_URL, {
    headers: {
      "x-app-type": "customer-web",
    },
  });

export const getLocationDetails = request =>
  api.getStream(LOCATION_DETAILS_URL, {
    params: request.getDefinedValues(),
  });

export const getPublicLocationDetails = request =>
  api.getStream(PUBLIC_LOCATION_DETAILS_URL, {
    params: request.getDefinedValues(),
  });

export const getPackagesNSA = request =>
  api.getStream(PACKAGES_NSA_URL, {
    params: request.getDefinedValues(),
  });
