import React from "react";
import PropTypes from "prop-types";
import { componentFromStream } from "recompose";
import { Field } from "redux-form";
import MuiPhoneNumber from "material-ui-phone-number-uzpost";

export const PhoneFieldComponent = componentFromStream(propsStream =>
  propsStream.combineLatest(
    ({
      input,
      meta,
      size,
      margin,
      variant,
      defaultCountry,
      style,
      ...custom
    }) => (
      <div style={{ width: "100%" }}>
        <div>
          <MuiPhoneNumber
            {...input}
            {...custom}
            defaultCountry={custom.country ? custom.country.toLowerCase() : ""}
            country={custom.country ? custom.country.toLowerCase() : ""}
            value={input.value}
            specialLabel={custom.label}
            inputExtraProps={{
              required: custom.required,
              autoFocus: true,
            }}
            containerClass={custom.className}
            variant={variant}
            size={size}
            margin={margin}
            error={Boolean(!meta.active && meta.touched && meta.error)}
            helperText={!meta.active && meta.touched && meta.error}
            InputProps={{
              name: input.name,
              "data-cy": custom["data-cy"],
            }}
            enableTerritories={true}
            enableSearch={true}
            style={{
              ...style,
              direction: "ltr",
              width: "100%",
            }}
          />
        </div>
        {/* {(boolean(!meta.active && meta.touched && meta.error)) && ( */}
        {/*  <span */}
        {/*    style={{ */}
        {/*      color: "#f44336", */}
        {/*      marginLeft: "14px", */}
        {/*      marginRight: "14px", */}
        {/*      marginTop: 4, */}
        {/*      fontSize: "0.75rem", */}
        {/*    }} */}
        {/*  > */}
        {/*    {!meta.active && meta.touched && meta.error} */}
        {/*  </span> */}
        {/* )} */}
      </div>
    ),
  ),
);

PhoneFieldComponent.propTypes = {
  "data-cy": PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  phoneCode: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  helperText: PropTypes.string,
  country: PropTypes.string,
  change: PropTypes.func,
  onChange: PropTypes.func,
  disableDropdown: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  size: PropTypes.oneOf(["large", "medium", "small"]),
  withOutSuccess: PropTypes.bool,
  defaultCountry: PropTypes.bool,
};

PhoneFieldComponent.defaultProps = {
  variant: "outlined",
  size: "small",
};

export default function FormPhoneField(props) {
  return <Field {...props} component={PhoneFieldComponent} />;
}
