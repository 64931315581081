import React from "react";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValueSelector, reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormCityAutoComplete from "../form/FormCityV2AutoComplete";
import FormCountyPhoneCodeField from "../form/FormCountyPhoneCodeField";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FormNeighborhoodV2AutoComplete from "../form/FormNeighborhoodV2AutoComplete";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import {
  findPhoneCodeByCountryName,
  formatText,
  parsePhone,
} from "../../helpers/FormatUtils";
import { validateString } from "../../helpers/ValidateUtils";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import AddressTypes from "../../constants/AddressType";
import { responsive } from "../../../shared/theme/jss-responsive";

const valueSelector = formValueSelector("AddressBookCreateForm");
const getValue = (name, formValue) => fp.defaultTo(0, fp.get(name, formValue));

const enhancer = compose(
  useSheet({
    map: { [responsive("$xs")]: { display: "none" } },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
    spanCode: {
      width: "18%",
      marginBottom: "10px",
      marginRight: "1%",
      fontSize: "16px",
      textAlign: "right",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
    formValues: valueSelector(state, "country", "phoneCode", "city"),
  })),
  withContext(
    {
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.update("initialValues", fp.toPlainObject), props => ({
          ...props.initialValues,
        })),
      );

    const onSubmit = (values, dispatch, props) => props.actualOnSubmit(values);

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit && onSubmit,
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AddressBookCreateForm",
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      country:
        (!values.country || !values.country.id) &&
        props.getLocalisationMessage(
          "please_select_country",
          "Please select country",
        ),
      city:
        (!values.city || !values.city.id) &&
        props.getLocalisationMessage(
          "please_select_city",
          "Please select city",
        ),
      addressType:
        !values.addressType &&
        props.getLocalisationMessage("address_type_required"),
      address:
        !values.address && props.getLocalisationMessage("address_required"),
    }),
  }),
);

AddressBookFormNew.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  isRTL: PropTypes.bool,
  isCustomer: PropTypes.bool,
};

function AddressBookFormNew(props) {
  const { classes, getLocalisationMessage, isRTL } = props;
  const countryId = getValue("country.id", fp.get("formValues", props));
  const cityId = getValue("city.id", fp.get("formValues", props));

  return (
    <FlexBox
      element={<form onSubmit={props.handleSubmit} />}
      container={8}
      direction="column"
    >
      <FlexBox gutter={8} element={<Card />} direction="column">
        <FlexBox element={<CardContent />} direction="column">
          <PageLoading isLoading={props.submitting} />

          <FlexBox gutter={8}>
            <FlexBox flex={true} direction="column">
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="name"
                    label={`${getLocalisationMessage("name", "Name")} *`}
                    fullWidth={true}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormCountryAutoComplete
                    name="country"
                    fullWidth={true}
                    onChange={v => {
                      props.change("city", null);
                      props.change("neighborhood", null);
                      if (v.id)
                        props.change(
                          "phoneCode",
                          fromJS({ code: findPhoneCodeByCountryName(v.name) }),
                        );
                    }}
                    label={`${getLocalisationMessage("country", "Country")} *`}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormCityAutoComplete
                    name="city"
                    fullWidth={true}
                    onChange={() => {
                      props.change("neighborhood", null);
                    }}
                    label={`${getLocalisationMessage("city", "City")}*`}
                    disabled={!countryId}
                    countryId={countryId}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormNeighborhoodV2AutoComplete
                    includeUnknownNeighborhood={false}
                    fullWidth={true}
                    name="neighborhood"
                    cityId={cityId}
                    countryId={countryId}
                    hintText={getLocalisationMessage("region", "Region")}
                    label={getLocalisationMessage("region", "Region")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormCountyPhoneCodeField
                    fullWidth={true}
                    className={
                      isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                    }
                    name="phoneCode"
                    label={`${getLocalisationMessage(
                      "phone_code",
                      "Phone Code",
                    )} *`}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} align="flex-end" justify="flex-end">
                  <FormTextField
                    fullWidth={true}
                    name="phone"
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                    parse={parsePhone}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="street"
                    fullWidth={true}
                    label={getLocalisationMessage("street", "Street")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="building"
                    fullWidth={true}
                    label={getLocalisationMessage("building", "Building")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="apartment"
                    fullWidth={true}
                    label={getLocalisationMessage("apartment", "Apartment")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="floor"
                    fullWidth={true}
                    label={getLocalisationMessage("floor", "Floor")}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="landmark"
                    fullWidth={true}
                    label={getLocalisationMessage("landmark", "Landmark")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="addressType"
                    label={`${getLocalisationMessage(
                      "address_type",
                      "Address Type",
                    )} *`}
                    fullWidth={true}
                    options={AddressTypes}
                    formatOption={value =>
                      formatText(getLocalisationMessage(value, value))
                    }
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="address"
                    fullWidth={true}
                    label={`${getLocalisationMessage("address", "Address")} *`}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox
          align="center"
          justify="space-around"
          element={<CardActions />}
        >
          {props.isCustomer && (
            <FlexBox style={{ width: "200px" }}>
              <FormCheckbox
                name="defaultAddress"
                label={getLocalisationMessage(
                  "default_address",
                  "Default Address",
                )}
              />
            </FlexBox>
          )}
          <FlexBox>
            <Button variant="text" type="submit">
              {getLocalisationMessage("save", "Save")}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AddressBookFormNew);
