import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  Paper,
  Toolbar,
  CardContent,
  ListItem,
  Button,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import TextWithLink from "../router/TextWithLink";
import Text, { DANGER, SUCCESS } from "../ui-core/Text";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import DataList, { DataListColumn } from "../data-list/DataList";
import MultiLineCell from "../data-list/MultiLineCell";
import { isEqualData } from "../../helpers/DataUtils";
import {
  formatDate,
  formatText,
  formatNumber,
} from "../../helpers/FormatUtils";
import { formatLocalisedPaymentType } from "../../helpers/OrderHelper";
import { pipeStreams, takeWhileInclusive } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { VALID, NOT_VALID } from "../../constants/DraftOrderStatus";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  useSheet({
    row: { cursor: "pointer" },
    rowError: { extend: "row", color: "red" },
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const progressStream = propsStream
          .distinctUntilKeyChanged("id")
          .switchMap(props =>
            props
              .getOrdersUpload(props.id)
              .takeLast(1)
              .catch(error => Observable.of({ error }))
              .repeatWhen(observable => observable.delay(5000))
              .let(
                takeWhileInclusive(
                  fp.flow(fp.get("payload.data.completed_date"), fp.isNil),
                ),
              ),
          )
          .startWith(null)
          .map(
            fp.flow(
              fp.get("payload.data"),
              fp.toPlainObject,
              fp.update("total", fp.toFinite),
              fp.update("current_total", fp.toFinite),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(progressStream, (props, progress) => ({
          ...props,
          progress,
          isProgressLoading: !progress.get("batch_id"),
        }));
      },
      propsStream => {
        const itemResponseStream = propsStream
          .distinctUntilKeyChanged("progress")
          .switchMap(props =>
            props.progress.get("completed_date")
              ? props
                  .getDraftOrderList(props.id)
                  .catch(error => Observable.of({ error }))
              : Observable.of({}),
          )
          .map(
            fp.flow(
              fp.toPlainObject,
              fp.update("pending", Boolean),
              fp.update(
                "payload",
                fp.flow(
                  fp.get("data"),
                  fp.toPlainObject,
                  fp.update("total", fp.toFinite),
                ),
              ),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(itemResponseStream, (props, itemResponse) => ({
            ...props,
            item: itemResponse.get("payload"),
            isItemLoading: itemResponse.get("pending"),
          }));
      },
    ),
  ),
);

OrderUploadDetails.propTypes = {
  classes: PropTypes.object,

  isItemLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),

  isProgressLoading: PropTypes.bool,
  progress: PropTypes.instanceOf(Map),

  id: PropTypes.number.isRequired,

  onBackClick: PropTypes.func.isRequired,
  onPublishClick: PropTypes.func.isRequired,
  onDraftSelect: PropTypes.func.isRequired,
  getOrdersUpload: PropTypes.func.isRequired,
  getDraftOrderList: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  createOrderHref: PropTypes.func,
  create: PropTypes.bool,
};

function OrderUploadDetails(props) {
  const { item, progress, classes, getLocalisationMessage } = props;

  return (
    <FlexBox direction="column" flex={true} gutter={8}>
      <PageLoading isLoading={props.isProgressLoading} />

      <Toolbar>
        <IconButton onClick={props.onBackClick}>
          <ArrowBack />
        </IconButton>

        <h6>
          {getLocalisationMessage("batch", "Batch")} #{props.id}
        </h6>
      </Toolbar>

      <FlexBox direction="column">
        <Card>
          <CardContent>
            <FlexBox gutter={8} justify="space-between">
              <FlexBox flex={true} direction="column">
                <ListItem disabled={true}>
                  <ListItemText
                    primary={getLocalisationMessage("total", "Total")}
                    secondary={formatNumber(progress.get("total"))}
                  />
                </ListItem>
              </FlexBox>

              <FlexBox flex={true} direction="column">
                <ListItem disabled={true}>
                  <ListItemText
                    primary={getLocalisationMessage("processed", "Processed")}
                    secondary={formatNumber(progress.get("current_total"))}
                  />
                </ListItem>
              </FlexBox>

              {progress.get("completed_date") ? (
                <FlexBox flex={true} direction="column">
                  <ListItem disabled={true}>
                    <ListItemText
                      primary={getLocalisationMessage(
                        "completed_date",
                        "Completed Date",
                      )}
                      secondary={formatDate(progress.get("completed_date"))}
                    />
                  </ListItem>
                </FlexBox>
              ) : (
                <FlexBox flex={true} direction="column">
                  <ListItem disabled={true}>
                    <ListItemText
                      primary={getLocalisationMessage("progress", "Progress")}
                      secondary={`${formatNumber(
                        (progress.get("current_total") * 100) /
                          progress.get("total"),
                      ) || 0}%`}
                    />
                  </ListItem>
                </FlexBox>
              )}

              {Boolean(progress.get("completed_date")) && (
                <FlexBox align="center">
                  <Button onClick={props.onPublishClick}>
                    {getLocalisationMessage("publish", "Publish")}
                  </Button>
                </FlexBox>
              )}
            </FlexBox>
          </CardContent>
        </Card>
      </FlexBox>

      {Boolean(progress.get("completed_date")) && (
        <FlexBox flex={true} direction="column">
          <FlexBox flex={true} element={<Paper />}>
            <DataList
              overscanRowCount={10}
              isLoading={props.isItemLoading}
              rowGetter={row => item.getIn(["items", row.index])}
              rowCount={item.get("items") ? item.get("items").size : 0}
              getRowClassName={row =>
                row.index === -1
                  ? ""
                  : item.getIn(["items", row.index, "status"]) !== NOT_VALID
                  ? classes.row
                  : classes.rowError
              }
              onRowClick={row => props.onDraftSelect(row.rowData.get("id"))}
            >
              <DataListColumn
                label={getLocalisationMessage("id", "ID")}
                width={120}
                dataKey="id"
                disableSort={true}
                justifyContent="center"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.get("id")}
                    secondLine={
                      row.cellData.get("status") === VALID ? (
                        <Text type={SUCCESS}>
                          {getLocalisationMessage("valid", "Valid")}
                        </Text>
                      ) : (
                        <Text type={DANGER}>
                          {getLocalisationMessage("invalid", "Invalid")}
                        </Text>
                      )
                    }
                  />
                )}
              />

              <DataListColumn
                width={80}
                label={getLocalisationMessage("status", "Status")}
                disableSort={true}
                dataKey="is_created"
                justifyContent="center"
                cellRenderer={row =>
                  formatText(
                    getLocalisationMessage(
                      row.cellData.get("is_created"),
                      row.cellData.get("is_created"),
                    ),
                  )
                }
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "weight_category",
                  "Weight Category",
                )}
                dataKey="weight_category"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "weight_category",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "weight_category",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("service_type", "Service Type")}
                dataKey="service_type"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["service_type", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "service_type",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "sender_country",
                  "Sender Country",
                )}
                dataKey="sender_country"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "sender_country",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_country",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("sender_city", "Sender City")}
                dataKey="sender_city"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["sender_city", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_city",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("sender_area", "Sender Area")}
                dataKey="sender_area"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["sender_area", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_area",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("sender_street", "Sender Street")}
                dataKey="sender_street"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["sender_street", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_street",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("sender_office", "Sender Office")}
                dataKey="sender_office"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["sender_office", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_office",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "sender_landmark",
                  "Sender Landmark",
                )}
                dataKey="sender_landmark"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "sender_landmark",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_landmark",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("sender_name", "Sender Name")}
                dataKey="sender_name"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["sender_name", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_name",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("sender_email", "Sender Email")}
                dataKey="sender_email"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["sender_email", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_email",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "sender_phone_code",
                  "Sender Phone Code",
                )}
                dataKey="sender_phone_code"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "sender_phone_code",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_phone_code",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "sender_phone_number",
                  "Sender Phone Number",
                )}
                dataKey="sender_phone_number"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "sender_phone_number",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "sender_phone_number",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_country",
                  "Recipient Country",
                )}
                dataKey="recipient_country"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_country",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_country",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_city",
                  "Recipient City",
                )}
                dataKey="recipient_city"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_city",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_city",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_area",
                  "Recipient Area",
                )}
                dataKey="recipient_area"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_area",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_area",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_street",
                  "Recipient Street",
                )}
                dataKey="recipient_street"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_street",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_street",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_office",
                  "Recipient Office",
                )}
                dataKey="recipient_office"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_office",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_office",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_landmark",
                  "Recipient Landmark",
                )}
                dataKey="recipient_landmark"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_landmark",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_landmark",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_name",
                  "Recipient Name",
                )}
                dataKey="recipient_name"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_name",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_name",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_email",
                  "Recipient Email",
                )}
                dataKey="recipient_email"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_email",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_email",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_phone_code",
                  "Recipient Phone Code",
                )}
                dataKey="recipient_phone_code"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_phone_code",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_phone_code",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "recipient_phone_number",
                  "Recipient Phone Number",
                )}
                dataKey="recipient_phone_number"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "recipient_phone_number",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "recipient_phone_number",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("cod_amount", "COD Amount")}
                dataKey="amount"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["amount", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "amount",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "parcel_details",
                  "Parcel Details",
                )}
                dataKey="parcel_details"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "parcel_details",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "parcel_details",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "if_recipient_not_available",
                  "if Recipient Not Available",
                )}
                dataKey="not_available"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["not_available", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "not_available",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("payment_done", "Payment Done")}
                dataKey="payment_done"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["payment_done", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "payment_done",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage(
                  "special_instructions",
                  "Special Instructions",
                )}
                dataKey="special_instructions"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn([
                      "special_instructions",
                      "content",
                    ])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "special_instructions",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("payment_type", "Payment Type")}
                dataKey="payment_type"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={formatLocalisedPaymentType(
                      row.cellData.getIn(["payment_type", "content"]),
                      getLocalisationMessage,
                    )}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "payment_type",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("public_charge", "Public Charge")}
                dataKey="public_charge"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["public_charge", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "public_charge",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("value", "Value")}
                dataKey="value"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["value", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "value",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />

              <DataListColumn
                width={120}
                disableSort={true}
                label={getLocalisationMessage("reference_id", "Reference ID")}
                dataKey="reference"
                cellRenderer={row => (
                  <MultiLineCell
                    firstLine={row.cellData.getIn(["reference", "content"])}
                    secondLine={
                      <Text type={DANGER}>
                        {row.cellData.getIn([
                          "reference",
                          "failed_field",
                          "message",
                        ])}
                      </Text>
                    }
                  />
                )}
              />
              {props.create && (
                <DataListColumn
                  width={120}
                  disableSort={true}
                  label={getLocalisationMessage("order_id", "Order Id")}
                  dataKey="created_order_id"
                  cellRenderer={row => (
                    <Text type={DANGER}>
                      <TextWithLink
                        to={props.createOrderHref(
                          row.cellData.get("created_order_id"),
                        )}
                      >
                        {row.cellData.get("created_order_number")}
                      </TextWithLink>
                    </Text>
                  )}
                />
              )}
            </DataList>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
}

export default enhancer(OrderUploadDetails);
