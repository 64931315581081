import React from "react";
import { Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { connect } from "react-redux";
import { PanTo } from "react-google-map-components";
import FormTextField from "../form/FormTextField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import BrandMarker from "../maps/BrandMarker";
import GoogleMapWrapper from "../maps/GoogleMapWrapper";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { isEqualData } from "../../helpers/DataUtils";
import { createCoordinatesValidator } from "../../helpers/FormUtils";
import { parsePhone } from "../../helpers/FormatUtils";
import {
  validateDetails,
  isValidCoordinates,
} from "../../helpers/ValidateUtils";
import { getMarketplaceCountry } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import { responsive } from "../../../shared/theme/jss-responsive";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import {
  getMapProvider,
  isMapKeyAvailable,
} from "../../../shared/reducers/AppReducer";
// import { GOOGLE_MAP_PROVIDER } from '../../../shared/constants/MapsControllerConstants'
import { PanTo as LeafletPanTo } from "react-leflet-map-components";
import OSMBrandMaker from "../maps/osm/BrandMaker";

const enhancer = compose(
  useSheet({ map: { [responsive("$xs")]: { display: "none" } } }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    baseCountry: getMarketplaceCountry(state),
    mapProvider: getMapProvider(state),
    isMapKeyAvailable: isMapKeyAvailable(state),
  })),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.update("initialValues", fp.toPlainObject), props => ({
          ...props.initialValues,
          markLocation: {
            lat: props.initialValues.lat,
            lng: props.initialValues.lon,
            address: props.initialValues.address,
          },
        })),
      );

    const onSubmit = ({ markLocation, ...values }, dispatch, props) =>
      props.actualOnSubmit({
        ...values,
        lat: markLocation.lat,
        lon: markLocation.lng,
        address: markLocation.address,
      });

    return propsStream.combineLatest(
      initialValuesStream,
      (props, initialValues) => ({
        ...props,
        initialValues,
        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit && onSubmit,
      }),
    );
  }),
  reduxForm({
    enableReinitialize: true,
    form: "AddressBookCreateForm",
    validate: (values, props) => ({
      details:
        validateDetails(values.details) &&
        props.getLocalisationMessage(
          "details_is_required",
          "Details is required",
        ),
    }),
  }),
  formValues({ markLocation: "markLocation" }),
);

AddressBookForm.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  markLocation: PropTypes.object,
  baseCountry: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func.isRequired,
  mapProvider: PropTypes.string,
  isMapKeyAvailable: PropTypes.bool,
};

function AddressBookForm(props) {
  const { classes, baseCountry, getLocalisationMessage } = props;

  // const isGoogleMapProvider = props.mapProvider === GOOGLE_MAP_PROVIDER;

  return (
    <FlexBox
      element={<form onSubmit={props.handleSubmit} />}
      container={8}
      direction="column"
    >
      <FlexBox gutter={8} element={<Card />} direction="column">
        <FlexBox element={<CardContent />} direction="column">
          <PageLoading isLoading={props.submitting} />

          <FlexBox gutter={8}>
            <FlexBox flex={true} direction="column">
              <FormTextField
                name="name"
                label={getLocalisationMessage("name", "Name")}
                fullWidth={true}
              />

              <FormTextField
                fullWidth={true}
                name="contactName"
                label={getLocalisationMessage("contact_name", "Contact Name")}
              />

              <FormTextField
                fullWidth={true}
                name="companyName"
                label={getLocalisationMessage("company_name", "Company Name")}
              />

              <FormTextField
                name="email"
                fullWidth={true}
                label={getLocalisationMessage("email_address", "Email Address")}
              />

              <FormTextField
                name="phone"
                fullWidth={true}
                parseOnBlur={parsePhone}
                label={getLocalisationMessage("phone_number", "Phone Number")}
              />

              <FormGeoAutoComplete
                name="markLocation"
                fullWidth={true}
                countryCode={baseCountry.get("description", "AE")}
                validate={createCoordinatesValidator(
                  getLocalisationMessage("select_address", "Select Address"),
                )}
                label={`${getLocalisationMessage("address", "Address")} *`}
                hintText={getLocalisationMessage(
                  "type_to_search_3_letters",
                  "Type to Search (3 letters minimum)",
                )}
              />

              <FormTextField
                name="details"
                fullWidth={true}
                label={`${getLocalisationMessage("details", "Details")} *`}
              />
            </FlexBox>

            <FlexBox flex={true} direction="column" className={classes.map}>
              {props.isMapKeyAvailable ? (
                <GoogleMapWrapper
                  onClick={event =>
                    props.change("markLocation", {
                      lat: event.latLng.lat(),
                      lng: event.latLng.lng(),
                    })
                  }
                >
                  {isValidCoordinates(props.markLocation) && (
                    <div>
                      <PanTo latLng={props.markLocation} />

                      <BrandMarker position={props.markLocation} />
                    </div>
                  )}
                </GoogleMapWrapper>
              ) : (
                <LeafletMapWrapper
                  onClick={event => {
                    props.change("markLocation", {
                      lat: event.latLng.lat(),
                      lng: event.latLng.lng(),
                    });
                  }}
                >
                  {isValidCoordinates(props.markLocation) && (
                    <div>
                      <LeafletPanTo latLng={props.markLocation} />
                      <OSMBrandMaker position={props.markLocation} />
                    </div>
                  )}
                </LeafletMapWrapper>
              )}
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox element={<CardActions />}>
          <Button variant="text" type="submit">
            {getLocalisationMessage("save", "Save")}
          </Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AddressBookForm);
