import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox from "../ui-core/FlexBox";
import LinkButton from "../ui-core/LinkButton";
import DataList, { DataListColumn } from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText, formatNumber } from "../../helpers/FormatUtils";
import { pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const {
          handler: onRequestRefresh,
          stream: onRequestRefreshStream,
        } = createEventHandler();
        const responseStream = propsStream
          .take(1)
          .switchMap(props =>
            props
              .getOrderUploadList()
              .repeatWhen(() => onRequestRefreshStream)
              .catch(error => Observable.of({ error })),
          )
          .map(
            fp.flow(
              fp.update("pending", Boolean),
              fp.update("payload", fp.flow(fp.get("data"), fp.toArray)),
              fromJS,
            ),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(responseStream, (props, response) => ({
          ...props,
          onRequestRefresh,
          list: response.get("payload"),
          isLoading: response.get("pending"),
        }));
      },
      propsStream => {
        const sideEffectsStream = propsStream
          .filter(props => !props.isLoading)
          .distinctUntilKeyChanged("list", isEqualData)
          .do(props => {
            if (props.list.isEmpty()) {
              props.onOrderCreateUploadClick();
            }
          })
          .switchMapTo(Observable.never())
          .startWith(null);

        return propsStream
          .distinctUntilChanged(isEqualData)
          .combineLatest(sideEffectsStream, fp.identity);
      },
    ),
  ),
);

OrderUploadList.propTypes = {
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  getOrderUploadList: PropTypes.func,
  onOrderUploadClick: PropTypes.func.isRequired,
  onOrderCreateUploadClick: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderUploadList(props) {
  const { getLocalisationMessage } = props;
  return (
    <FlexBox flex={true} direction="column" element={<Paper />}>
      <DataList
        overscanRowCount={10}
        rowCount={props.list.size}
        isLoading={props.isLoading}
        rowGetter={row => props.list.get(row.index)}
        altHeader={
          <div>
            <Button onClick={props.onOrderCreateUploadClick}>
              {getLocalisationMessage("upload_file", "Upload File")}
            </Button>
          </div>
        }
      >
        <DataListColumn
          width={120}
          label={getLocalisationMessage("id", "ID")}
          dataKey="id"
          cellRenderer={row => (
            <LinkButton
              onClick={() => props.onOrderUploadClick(row.cellData.get("id"))}
            >
              {row.cellData.get("id")}
            </LinkButton>
          )}
        />
        <DataListColumn
          width={120}
          label={getLocalisationMessage("uploaded_by", "Uploaded By")}
          dataKey="id"
          cellRenderer={row =>
            row.cellData.get("uploaded_by")
              ? row.cellData.get("uploaded_by")
              : getLocalisationMessage("na", "N/A")
          }
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("type", "Type")}
          dataKey="domestic"
          justifyContent="center"
          cellRenderer={row =>
            row.cellData.get("domestic")
              ? getLocalisationMessage("domestic", "Domestic")
              : getLocalisationMessage("international", "International")
          }
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("process_date", "Process Date")}
          dataKey="completed_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("completed_date")} />
          )}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("of_orders", "# of orders")}
          dataKey="total"
          justifyContent="end"
          cellRenderer={row => formatNumber(row.cellData.get("total"))}
        />

        <DataListColumn
          width={120}
          label={getLocalisationMessage("status", "Status")}
          dataKey="status"
          justifyContent="center"
          cellRenderer={row =>
            formatText(
              getLocalisationMessage(
                row.cellData.get("status"),
                row.cellData.get("status"),
              ),
            )
          }
        />
      </DataList>
    </FlexBox>
  );
}

export default enhancer(OrderUploadList);
