import React from "react";
import { List, Map } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import PriceWrapper from "../ui-core/PriceWrapper";
import { formatDate } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getAttachmentUrl } from "../../api/shared/FileApi";
import { ExpandMore } from "@material-ui/icons";
import FlexBox, { JUSTIFY_END } from "../ui-core/FlexBox";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
);

CustomerTransactions.propTypes = {
  selectedId: PropTypes.number,
  onItemSelect: PropTypes.func.isRequired,
  itemFetching: PropTypes.bool.isRequired,
  item: PropTypes.instanceOf(Map).isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  endingBalance: PropTypes.instanceOf(Map).isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerTransactions(props) {
  const { getLocalisationMessage } = props;
  return (
    <div>
      <Card>
        <CardHeader
          title={
            <span>
              {getLocalisationMessage(
                "your_current_ending_balance",
                "Your current ending balance:",
              )}{" "}
              {fp.isFinite(props.endingBalance.get("total")) ? (
                <PriceWrapper
                  price={props.endingBalance.get("total")}
                  code={props.endingBalance.getIn(["currency", "code"])}
                />
              ) : (
                getLocalisationMessage("loading", "Loading ...")
              )}
            </span>
          }
        />
      </Card>
      {props.list
        .sortBy(item => item.get("id"))
        .reverse()
        .map(listItem => (
          <Accordion
            key={listItem.get("id")}
            style={{
              margin: "1rem 0",
              color: " rgba(0, 0, 0, 0.87)",
              backgroundColor: " rgb(255, 255, 255)",
              transition: " all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
              boxSizing: " border-box",
              boxShadow:
                " rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px",
              borderRadius: " 2px",
              zIndex: " 1",
            }}
            onChange={value =>
              props.onItemSelect(value ? listItem.get("id") : null)
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <FlexBox direction="column">
                <strong>
                  {getLocalisationMessage("ending_balance", "Ending Balance:")}{" "}
                  <PriceWrapper
                    price={listItem.get("ending_balance")}
                    code={listItem.getIn(["currency", "code"])}
                  />
                </strong>
                {formatDate(listItem.get("from_date"))} -{" "}
                {formatDate(listItem.get("to_date"))}
              </FlexBox>
            </AccordionSummary>
            <AccordionDetails>
              <FlexBox flex={true} direction="column">
                {props.selectedId === listItem.get("id") &&
                  props.itemFetching && <CircularProgress />}
                {props.selectedId === listItem.get("id") &&
                  !props.itemFetching && (
                    <Table selectable={false}>
                      <TableBody selectable={false} displayRowCheckbox={false}>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage(
                              "opening_balance",
                              "Opening Balance",
                            )}
                          </TableCell>
                          <TableCell colSpan={2}>
                            <PriceWrapper
                              price={props.item.get("opening_balance")}
                              withCode={false}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage("cod_amount", "COD Amount")}
                          </TableCell>
                          <TableCell>
                            <PriceWrapper
                              price={props.item.get("cod_amount")}
                              withCode={false}
                            />
                          </TableCell>
                          <TableCell>
                            {getLocalisationMessage("for", "For")}{" "}
                            {props.item.get("cod_count")}{" "}
                            {getLocalisationMessage(
                              "delivered_shipments",
                              "delivered shipments",
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage(
                              "shipping_fee",
                              "Shipping Fee",
                            )}
                          </TableCell>
                          <TableCell>
                            <PriceWrapper
                              price={props.item.get("shipping_fee")}
                              withCode={false}
                            />
                          </TableCell>
                          <TableCell>
                            {getLocalisationMessage(
                              "deducted_for",
                              "deducted for",
                            )}{" "}
                            {props.item.get("shipping_count")}{" "}
                            {getLocalisationMessage("shipments", "shipments")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage("rto_fee", "RTO fee")}
                          </TableCell>
                          <TableCell>
                            <PriceWrapper
                              price={props.item.get("rto_fee")}
                              withCode={false}
                            />
                          </TableCell>
                          <TableCell>
                            {getLocalisationMessage(
                              "deducted_for",
                              "deducted for",
                            )}{" "}
                            {props.item.get("rto_count")}{" "}
                            {getLocalisationMessage("shipments", "shipments")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage("paid_out", "Paid out")}
                          </TableCell>
                          <TableCell>
                            <PriceWrapper
                              price={props.item.get("paid_out")}
                              withCode={false}
                            />
                          </TableCell>
                          <TableCell>
                            {getLocalisationMessage(
                              "paid_out_to_your_account",
                              "Paid out to your account",
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage("cod_fee", "COD Fee")}
                          </TableCell>
                          <TableCell colSpan={2}>
                            <PriceWrapper
                              price={props.item.get("cod_fee")}
                              withCode={false}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage(
                              "bank_transfer_fee",
                              "Bank transfer fee",
                            )}
                          </TableCell>
                          <TableCell>
                            <PriceWrapper
                              price={props.item.get("bank_transfer_fee")}
                              withCode={false}
                            />
                          </TableCell>
                          <TableCell>
                            {getLocalisationMessage(
                              "one_off_charge_for_cod_bank_transfer",
                              "One off charge for COD bank transfer",
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {getLocalisationMessage(
                              "your_ending_balance",
                              "Your Ending Balance",
                            )}
                          </TableCell>
                          <TableCell colSpan={2}>
                            <PriceWrapper
                              price={props.item.get("ending_balance")}
                              withCode={false}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                <FlexBox justify={JUSTIFY_END}>
                  {listItem.get("cod_statement_id") && (
                    <Button
                      style={{
                        marginTop: ".5rem",
                      }}
                      onClick={() => {
                        Promise.resolve(
                          getAttachmentUrl(listItem.get("cod_statement_id")),
                        ).then(response => {
                          window.location.href = response.data;
                        });
                      }}
                    >
                      {" "}
                      {getLocalisationMessage(
                        "download_statement",
                        "Download Statement",
                      )}{" "}
                    </Button>
                  )}
                </FlexBox>
              </FlexBox>
            </AccordionDetails>
          </Accordion>
        ))
        .toArray()}
    </div>
  );
}

export default enhancer(CustomerTransactions);
