import { OrderedSet } from "immutable";

export const RTO_SCORE = "rto_score";
export const TURN_AROUND_TIME = "turn_around_time";
export const CUSTOMER_FEEDBACK = "customer_feedback";
export const COD_TURN_AROUND_TIME = "cod_turn_around_time";

export default OrderedSet.of(
  TURN_AROUND_TIME,
  COD_TURN_AROUND_TIME,
  RTO_SCORE,
  CUSTOMER_FEEDBACK,
);
