import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserAvatar from "./UserAvatar";
import { getIsRTL } from "../../reducers/LocalizationReducer";
import { NotificationsActiveOutlined } from "@material-ui/icons";
import FlexBox from "../ui-core/FlexBox";

const enhancer = compose(
  connect((state) => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    avatar: {
      width: "36px",
      borderRadius: "50%",
      textAlign: "center",
      display: "table-cell",
      textTransform: "uppercase",
      color: "white",
      verticalAlign: "middle",
    },
    nameBox: {
      paddingLeft: ".5rem",
      display: "flex",
      alignItems: "center",
    },
  }),
);

AvatarWithName2.propTypes = {
  sheet: PropTypes.object,
  imagePath: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
  isNotification: PropTypes.bool,
};
AvatarWithName2.defaultProps = {};

function AvatarWithName2({
  sheet: { classes },
  name,
  imagePath,
  children,
  isNotification,
}) {
  return (
    <FlexBox style={{ margin: "0 .5rem" }}>
      <div className={classes.avatar}>
        {isNotification ? (
          <NotificationsActiveOutlined color="error" />
        ) : (
          <UserAvatar
            style={{ objectFit: "contain" }}
            src={imagePath}
            name={name}
            alt={name}
            size={36}
          />
        )}
      </div>
      <div className={classes.nameBox}>{children}</div>
    </FlexBox>
  );
}

export default enhancer(AvatarWithName2);
