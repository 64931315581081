import { api } from "./BaseApi";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const PAYFORT_SETTINGS_URL = `${API_ROOT_URL}/payfort/settings`;
const PAYFORT_SETTINGS_ANY_URL = `${API_ROOT_URL}/payfort/settings/any`;

export const getOrderPayfortSettings = id =>
  api.getStream(PAYFORT_SETTINGS_URL, { params: { order_id: id } });

export const getPayfortSettingsAny = params =>
  api.getStream(PAYFORT_SETTINGS_ANY_URL, { params });
