import React from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import TextWithLink from "../router/TextWithLink";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, { DataListColumn } from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { formatText } from "../../helpers/FormatUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  PENDING,
  APPROVED,
  COMPLETED,
} from "../../constants/CODTransferStatus";

const NA = "N/A";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CODWithdrawTable.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  list: PropTypes.instanceOf(List),
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  createCODWithdrawHref: PropTypes.func,
  createCustomerHref: PropTypes.func,
  onRemoveClick: PropTypes.func,
  cardActionIcons: PropTypes.node,
  getLocalisationMessage: PropTypes.func,
};

CODWithdrawTable.defaultProps = {
  createCODWithdrawHref: fp.noop,
  createCustomerHref: fp.noop,
};

function CODWithdrawTable(props) {
  const list = props.list || List();
  const { getLocalisationMessage } = props;

  return (
    <DataList
      filter={props.filter}
      isLoading={props.isLoading}
      onFilterChange={props.onFilterChange}
      overscanRowCount={10}
      rowCount={list.size}
      totalCount={props.count}
      cardActionIcons={props.cardActionIcons}
      rowGetter={options => list.get(options.index)}
    >
      <DataListColumn
        width={96}
        label={getLocalisationMessage("id", "ID")}
        dataKey="id"
        disableSort={true}
        justifyContent="center"
        cellRenderer={row => (
          <strong>
            <TextWithLink
              to={props.createCODWithdrawHref(row.cellData.get("id"))}
            >
              {row.cellData.get("id")}
            </TextWithLink>
          </strong>
        )}
      />

      {props.filter.getValue("status") === COMPLETED && (
        <DataListColumn
          width={128}
          disableSort={true}
          label={getLocalisationMessage("reference", "Reference #")}
          dataKey="bank_reference"
          cellRenderer={row => <div>{row.cellData.get("bank_reference")}</div>}
        />
      )}

      <DataListColumn
        width={128}
        label={getLocalisationMessage("customer", "Customer")}
        dataKey="customer_name"
        cellRenderer={row => (
          <TextWithLink
            to={props.createCustomerHref(
              row.cellData.getIn(["customer", "id"]),
            )}
          >
            {row.cellData.getIn(["customer", "name"])}
          </TextWithLink>
        )}
      />

      {props.filter.getValue("status") === PENDING && (
        <DataListColumn
          width={128}
          label={getLocalisationMessage("created_date", "Created Date")}
          dataKey="created_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("created_date")} />
          )}
        />
      )}

      {props.filter.getValue("status") === APPROVED && (
        <DataListColumn
          width={128}
          label={getLocalisationMessage("approved_date", "Approved Date")}
          dataKey="approved_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("approved_date")} />
          )}
        />
      )}

      {props.filter.getValue("status") === COMPLETED && (
        <DataListColumn
          width={128}
          label={getLocalisationMessage("completed_date", "Completed Date")}
          dataKey="completed_date"
          justifyContent="center"
          cellRenderer={row => (
            <DateTimeCell date={row.cellData.get("process_date")} />
          )}
        />
      )}

      <DataListColumn
        width={128}
        label={getLocalisationMessage("total", "Total")}
        dataKey="amount"
        justifyContent="end"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("amount")}
            code={row.cellData.getIn(["currency", "code"])}
          />
        )}
      />

      <DataListColumn
        width={128}
        label={getLocalisationMessage("status", "Status")}
        dataKey="status"
        justifyContent="center"
        disableSort={true}
        cellRenderer={row => (
          <div>
            {getLocalisationMessage(row.cellData.get("status")) ||
              formatText(row.cellData.get("status")) ||
              getLocalisationMessage("na", NA)}
          </div>
        )}
      />

      {props.onRemoveClick && (
        <DataListColumn
          width={128}
          label={getLocalisationMessage("action", "Action")}
          dataKey="action"
          justifyContent="center"
          disableSort={true}
          cellRenderer={row => (
            <Button onClick={() => props.onRemoveClick(row.cellData.get("id"))}>
              {" "}
              {getLocalisationMessage("remove", "Remove")}{" "}
            </Button>
          )}
        />
      )}
    </DataList>
  );
}

export default enhancer(CODWithdrawTable);
