import { List } from "immutable";
import {
  COMMUNICATION_SETTINGS_LIST_URL,
  FINANCE_ORDER_LIST_URL,
  ORDER_LIST_URL,
  ORDER_UPLOAD_V2_URL,
  ORDERS_PER_POSTCODES_URL,
} from "./CustomerPathConstants";

// export const DASHBOARD = {
//   ind: 1,
//   group: "dashboard",
//   slug: "dashboard",
//   icon: "actionDashboard",
//   url: CHARTS_URL,
// };
export const DOMESTIC_ORDERS = {
  ind: 2,
  group: "orders",
  slug: "domestic_orders",
  icon: "truckDelivery",
  url: ORDER_LIST_URL,
};
export const UPLOAD_ORDERS_V2 = {
  ind: 2,
  group: "orders",
  slug: "new_upload_orders",
  icon: "fileFileUpload",
  url: ORDER_UPLOAD_V2_URL,
};
export const POSTCODES_COORDINATES = {
  ind: 2,
  group: "orders",
  slug: "coordinates_of_postcodes",
  icon: "communicationLocationOn",
  url: ORDERS_PER_POSTCODES_URL,
};
export const FINANCE_ORDERS = {
  ind: 3,
  group: "finance",
  slug: "orders",
  icon: "editorMonetizationOn",
  // icon: "actionAccountBalance",
  url: FINANCE_ORDER_LIST_URL,
};
export const COMMUNICATION_SETTINGS = {
  ind: 4,
  group: "settings",
  slug: "communication",
  icon: "actionSettings",
  // icon: "communicationIcon",
  url: COMMUNICATION_SETTINGS_LIST_URL,
};

export default List([DOMESTIC_ORDERS, UPLOAD_ORDERS_V2, POSTCODES_COORDINATES]);
