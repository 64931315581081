import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormNeighborhoodV2AutoComplete from "../form/FormNeighborhoodV2AutoComplete";

const enhancer = compose(
  useSheet({
    root: {
      width: "100%",
      paddingRight: 5,
    },
  }),
);

OrderNeighborhoodDefaultField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.object,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  countryId: PropTypes.number.isRequired,
  cityId: PropTypes.number,
};

function OrderNeighborhoodDefaultField(props) {
  const { classes, countryId, cityId } = props;

  return (
    <div className={classes.root}>
      <FormNeighborhoodV2AutoComplete
        includeUnknownNeighborhood={false}
        fullWidth={true}
        name={props.name}
        // readOnly={props.readOnly}
        countryId={countryId}
        cityId={cityId}
        hintText={props.label}
        onClick={() => props.onClick && props.onClick(props.readOnly)}
        label={props.label}
      />
    </div>
  );
}

export default enhancer(OrderNeighborhoodDefaultField);
