import React from "react";
import { Map, List } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, SubmissionError } from "redux-form";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  CardActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import reduxActions from "redux-form/es/actions";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import { formatText, parsePhone } from "../../helpers/FormatUtils";
import { pipeStreams, mergeSideEffectStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { VALID } from "../../constants/DraftOrderStatus";
import {
  getDraftOrderPayerOptions,
  getDraftOrderWeightOptions,
  getDraftOrderPaymentTypeOptions,
  getDraftOrderServiceTypeOptions,
  getDraftOrderNotAvailableOptions,
} from "../../api/shared/OrderUploadApi";

const valuesToDraft = fp.flow(
  fp.mapValues(fp.flow(fp.trim, content => ({ content }))),
  toSnakeCase,
);

const mapDraftErrors = fp.flow(
  toCamelCase,
  fp.mapValues("failedField.message"),
  fp.omitBy(fp.isEmpty),
);

const mapDraftValues = fp.flow(
  toCamelCase,
  fp.pick([
    "weightCategory",
    "serviceType",
    "senderCountry",
    "senderCity",
    "senderArea",
    "senderStreet",
    "senderOffice",
    "senderLandmark",
    "senderName",
    "senderEmail",
    "senderPhoneCode",
    "senderPhoneNumber",
    "recipientCountry",
    "recipientCity",
    "recipientArea",
    "recipientStreet",
    "recipientOffice",
    "recipientLandmark",
    "recipientName",
    "recipientEmail",
    "recipientPhoneCode",
    "recipientPhoneNumber",
    "amount",
    "parcelDetails",
    "notAvailable",
    "paymentDone",
    "specialInstructions",
    "paymentType",
    "publicCharge",
    "value",
    "reference",
    "packageName",
  ]),
  fp.mapValues(fp.flow(fp.get("content"), fp.trim)),
);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  renderIf(props => props.draftId > 0),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const payerOptionsStream = getDraftOrderPayerOptions().startWith(
          List(),
        );
        const weightOptionsStream = getDraftOrderWeightOptions().startWith(
          List(),
        );
        const paymentTypeOptionsStream = getDraftOrderPaymentTypeOptions().startWith(
          List(),
        );
        const serviceTypeOptionsStream = getDraftOrderServiceTypeOptions().startWith(
          List(),
        );
        const notAvailableOptionsStream = getDraftOrderNotAvailableOptions().startWith(
          List(),
        );

        const responseStream = propsStream
          .distinctUntilKeyChanged("draftId")
          .switchMap(props =>
            props.getDraftOrder(props.draftId).let(mapObjectResponseStream),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(
          responseStream,
          payerOptionsStream,
          weightOptionsStream,
          paymentTypeOptionsStream,
          serviceTypeOptionsStream,
          notAvailableOptionsStream,
          (
            props,
            response,
            payerOptions,
            weightOptions,
            paymentTypeOptions,
            serviceTypeOptions,
            notAvailableOptions,
          ) => ({
            ...props,
            payerOptions,
            weightOptions,
            paymentTypeOptions,
            serviceTypeOptions,
            notAvailableOptions,
            draft: response.get("payload"),
            draftLoading: response.get("pending"),
          }),
        );
      },
      propsStream => {
        const onSubmit = (values, dispatch, props) => {
          const draft = props.draft.merge(valuesToDraft(values)).toJS();

          return Promise.resolve(props.actualOnSubmit(draft)).then(
            fp.flow(fp.get("data"), response => {
              if (response.status !== VALID) {
                throw new SubmissionError(mapDraftErrors(response));
              }

              return response;
            }),
          );
        };

        return propsStream.map(props => ({
          ...props,
          actualOnSubmit: props.onSubmit,
          onSubmit: props.onSubmit ? onSubmit : props.onSubmit,
        }));
      },
    ),
  ),
  reduxForm({ form: "OrderUploadEditDialog" }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .filter(props => props.draft.has("id"))
        .distinctUntilKeyChanged("draft", isEqualData)
        .do(props => {
          const errors = mapDraftErrors(props.draft);
          const values = mapDraftValues(props.draft);
          const errorKeys = fp.keys(errors);

          props.initialize(values);
          props.dispatch(reduxActions.stopSubmit(props.form, errors));

          if (errorKeys.length > 0) {
            props.touch(...errorKeys);
          }
        }),
    );

    return propsStream.merge(sideEffectsStream);
  }),
);

OrderUploadEditDialog.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  draftLoading: PropTypes.bool,
  draft: PropTypes.instanceOf(Map),

  payerOptions: PropTypes.instanceOf(List),
  weightOptions: PropTypes.instanceOf(List),
  paymentTypeOptions: PropTypes.instanceOf(List),
  serviceTypeOptions: PropTypes.instanceOf(List),
  notAvailableOptions: PropTypes.instanceOf(List),

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,

  getDraftOrder: PropTypes.func,
  draftId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderUploadEditDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <ModalPaper
      open={true}
      onRequestClose={props.onRequestClose}
      title={`${getLocalisationMessage("edit_draft", "Edit Draft")} #${
        props.draftId
      }`}
    >
      <PageLoading isLoading={props.submitting || props.draftLoading} />

      <FlexBox
        flex="none"
        gutter={8}
        container={16}
        direction="column"
        element={<form onSubmit={props.handleSubmit} />}
      >
        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                title={getLocalisationMessage("shipment", "Shipment")}
              />
              <CardContent>
                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="weightCategory"
                      autoWidth={true}
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "weight_category",
                        "Weight Category",
                      )}
                      options={props.weightOptions}
                      disabled={props.serviceTypeOptions.isEmpty()}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormSelectField
                      name="serviceType"
                      autoWidth={true}
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "service_type",
                        "Service Type",
                      )}
                      options={props.serviceTypeOptions}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                      disabled={props.serviceTypeOptions.isEmpty()}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      readOnly={true}
                      fullWidth={true}
                      name="packageName"
                      label={getLocalisationMessage(
                        "package_name",
                        "Package name",
                      )}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="reference"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "reference_id",
                        "Reference ID",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormSelectField
                      name="notAvailable"
                      autoWidth={true}
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "if_recipient_not_available",
                        "If Recipient Not Available",
                      )}
                      options={props.notAvailableOptions}
                      disabled={props.notAvailableOptions.isEmpty()}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                    />
                  </FlexBox>
                </FlexBox>

                <FormTextField
                  name="parcelDetails"
                  rows={2}
                  rowsMax={2}
                  fullWidth={true}
                  multiLine={true}
                  label={getLocalisationMessage(
                    "parcel_details",
                    "Parcel Details",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                title={getLocalisationMessage("payments", "Payments")}
              />
              <CardContent>
                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormSelectField
                      name="paymentType"
                      autoWidth={true}
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "payment_type",
                        "Payment Type",
                      )}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                      options={props.paymentTypeOptions}
                      disabled={props.paymentTypeOptions.isEmpty()}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormSelectField
                      name="paymentDone"
                      autoWidth={true}
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "payment_done_by",
                        "Payment Done By",
                      )}
                      formatOption={x =>
                        getLocalisationMessage(x, formatText(x))
                      }
                      options={props.payerOptions}
                      disabled={props.payerOptions.isEmpty()}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="amount"
                      fullWidth={true}
                      label={getLocalisationMessage("cod_amount", "COD Amount")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="publicCharge"
                      fullWidth={true}
                      label={getLocalisationMessage(
                        "public_charge",
                        "Public Charge",
                      )}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="value"
                      fullWidth={true}
                      label={getLocalisationMessage("value", "Value")}
                    />
                  </FlexBox>
                </FlexBox>

                <FormTextField
                  name="specialInstructions"
                  rows={2}
                  rowsMax={2}
                  fullWidth={true}
                  multiLine={true}
                  label={getLocalisationMessage(
                    "special_instructions",
                    "Special Instructions",
                  )}
                />
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <FlexBox gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader title={getLocalisationMessage("sender", "Sender")} />
              <CardContent>
                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderCountry"
                      fullWidth={true}
                      label={getLocalisationMessage("country", "Country")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderCity"
                      fullWidth={true}
                      label={getLocalisationMessage("city", "City")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderArea"
                      fullWidth={true}
                      label={getLocalisationMessage("area", "Area")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderStreet"
                      fullWidth={true}
                      label={getLocalisationMessage("street", "Street")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderOffice"
                      fullWidth={true}
                      label={getLocalisationMessage("office", "Office")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderLandmark"
                      fullWidth={true}
                      label={getLocalisationMessage("landmark", "Landmark")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={6}>
                    <FormTextField
                      name="senderName"
                      fullWidth={true}
                      label={getLocalisationMessage("name", "Name")}
                    />
                  </FlexBox>

                  <FlexBox flex={6}>
                    <FormTextField
                      name="senderEmail"
                      fullWidth={true}
                      label={getLocalisationMessage("email", "Email")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={3}>
                    <FormTextField
                      name="senderPhoneCode"
                      fullWidth={true}
                      parseOnBlur={parsePhone}
                      label={getLocalisationMessage("phone_code", "Phone Code")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="senderPhoneNumber"
                      fullWidth={true}
                      parseOnBlur={parsePhone}
                      label={getLocalisationMessage(
                        "phone_number",
                        "Phone Number",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                title={getLocalisationMessage("recipient", "Recipient")}
              />
              <CardContent>
                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientCountry"
                      fullWidth={true}
                      label={getLocalisationMessage("country", "Country")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientCity"
                      fullWidth={true}
                      label={getLocalisationMessage("city", "City")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientArea"
                      fullWidth={true}
                      label={getLocalisationMessage("area", "Area")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientStreet"
                      fullWidth={true}
                      label={getLocalisationMessage("street", "Street")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientOffice"
                      fullWidth={true}
                      label={getLocalisationMessage("office", "Office")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientLandmark"
                      fullWidth={true}
                      label={getLocalisationMessage("landmark", "Landmark")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientName"
                      fullWidth={true}
                      label={getLocalisationMessage("name", "Name")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientEmail"
                      fullWidth={true}
                      label={getLocalisationMessage("email", "Email")}
                    />
                  </FlexBox>
                </FlexBox>

                <FlexBox flex={true} gutter={8}>
                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientPhoneCode"
                      fullWidth={true}
                      parseOnBlur={parsePhone}
                      label={getLocalisationMessage("phone_code", "Phone Code")}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTextField
                      name="recipientPhoneNumber"
                      fullWidth={true}
                      parseOnBlur={parsePhone}
                      label={getLocalisationMessage(
                        "phone_number",
                        "Phone Number",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <Card>
          <CardActions>
            <FlexBox justify="flex-end">
              <Button onClick={props.onRequestClose}>
                {getLocalisationMessage("dismiss", "Dismiss")}
              </Button>
              <Button type="submit">
                {getLocalisationMessage("submit", "Submit")}
              </Button>
            </FlexBox>
          </CardActions>
        </Card>
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(OrderUploadEditDialog);
