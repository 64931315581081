import React from "react";
import { compose, withContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormDateField from "../form/FormDateField";
import FormDateRange from "../form/FormDateRange";
import FormCustomerAutoComplete from "../form/FormCustomerAutoComplete";
import FlexBox, { ALIGN_CENTER } from "../ui-core/FlexBox";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  withContext(
    {
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
    },
    props => ({
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
    }),
  ),
  reduxForm({
    enableReinitialize: true,
    form: "CustomerTransactionsFilter",
  }),
);

CustomerTransactionsFilter.propTypes = {
  getCachedCustomer: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function CustomerTransactionsFilter(props) {
  const { getLocalisationMessage } = props;
  return (
    <div
      style={{
        // TODO Card not worked incomprehensible issue
        color: " rgba(0, 0, 0, 0.87)",
        backgroundColor: " rgb(255, 255, 255)",
        transition: " all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        boxSizing: " border-box",
        boxShadow:
          " rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px",
        borderRadius: " 2px",
        zIndex: " 1",
      }}
    >
      <CardContent>
        <FlexBox gutter={16} align={ALIGN_CENTER}>
          {Boolean(props.getCachedCustomer) && (
            <FlexBox flex={true}>
              <FormCustomerAutoComplete
                name="customer"
                fullWidth={true}
                hintText={getLocalisationMessage("customer", "Customer")}
              />
            </FlexBox>
          )}

          <FlexBox flex={true}>
            <FormDateRange
              fullWidth={true}
              MAR={true}
              startDate="fromDate"
              endDate="toDate"
              getLocalisationMessage={getLocalisationMessage}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormDateField
              name="fromDate"
              fullWidth={true}
              hintText={getLocalisationMessage("from_date", "From Date")}
            />
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <FormDateField
              name="toDate"
              fullWidth={true}
              hintText={getLocalisationMessage("to_date", "To Date")}
            />
          </FlexBox>
        </FlexBox>
      </CardContent>
    </div>
  );
}

export default enhancer(CustomerTransactionsFilter);
