import React, { useEffect, useState } from "react";
import { List } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { getFormValues, reduxForm, SubmissionError } from "redux-form";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import reduxActions from "redux-form/es/actions";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import ModalPaper from "../ui-core/ModalPaper";
import PageLoading from "../ui-core/PageLoading";
import { mapObjectResponseStream } from "../../helpers/ApiUtils";
import { renderIf } from "../../helpers/HOCUtils";
import {
  getValue,
  isEqualData,
  isEqualDataIn,
  toJS,
} from "../../helpers/DataUtils";
import { toCamelCase, toSnakeCase } from "../../helpers/CaseMapper";
import {
  formatPhone2,
  formatText,
  parsePhone,
} from "../../helpers/FormatUtils";
import { mergeSideEffectStreams, pipeStreams } from "../../helpers/StreamUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { VALID } from "../../constants/DraftOrderStatus";
import FormJMAutoComplete from "../form/FormJMAutoComplete";
import TransportationType from "../../constants/TransportationType";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import DataListFilter from "../../helpers/DataListFilter";
import { CM_KG } from "../../constants/UnitsOfMeasureTypes";
import { getCourierPrices } from "../../api/v2/admin/AdminOrderApi";
import { Observable } from "rxjs";
import {
  dots,
  mapResponseListAsList,
  money,
  showedPrice,
} from "../orders-core/AdminPostForm";
import OrderPaymentTypes from "../../constants/OrderPaymentTypes";
import PostcodeAutoCompleteWithJM from "../form/PostcodeAutoCompleteWithJM";
import _ from "lodash";
import { TransitionUp } from "../dialog/TransitionUp";
import SimpleAutocomplete from "../form/SimpleAutocomplete";
import {
  getCachedPostServices,
  getPostServicesPredictions2,
} from "../../api/shared/OrderApi";
import { Add, Delete, KeyboardArrowLeft, Remove } from "@material-ui/icons";
import { MERCHANTS } from "../../constants/MerchantTypes";

export const valuesToDraft = fp.flow(
  fp.mapValues(fp.flow(fp.trim, content => ({ content }))),
  toSnakeCase,
);
const getValues = getFormValues("OrderUploadEditDialog");
const mapDraftErrors = fp.flow(
  toCamelCase,
  fp.mapValues("failedField.message"),
  fp.omitBy(fp.isEmpty),
);
const mapDraftValues = fp.flow(
  toCamelCase,
  fp.pick([
    "barcode",
    "serviceType",
    "transportationType",
    "referenceId",
    "promisedDate",
    "paymentType",
    "paidByPostmark",
    "weight",
    "codAmount",
    "parcelValue",
    "senderJurisdiction",
    "senderPostcode",
    "senderName",
    "senderPhone",
    "senderAddress",
    "recipientJurisdiction",
    "recipientPostcode",
    "recipientName",
    "recipientPhone",
    "recipientAddress",
  ]),
  fp.mapValues(fp.flow(fp.get("content"), fp.trim)),
);

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  withContext(
    {
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
      getCachedPostServices: PropTypes.func.isRequired,
      getPostServicesPredictions: PropTypes.func.isRequired,
      getCachedCustomer: PropTypes.func.isRequired,
      getCustomerPredictions: PropTypes.func.isRequired,
      getPaymentMethods: PropTypes.func.isRequired,
      getCachedJM: PropTypes.func.isRequired,
      getJMPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
      getCachedPostServices: props.getCachedPostServices,
      getPostServicesPredictions: props.getPostServicesPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getPaymentMethods: props.getPaymentMethods,
      getCachedJM: props.getCachedJM,
      getJMPredictions: props.getJMPredictions,
    }),
  ),
  renderIf(props => props.draftId > 0),
  reduxForm({
    form: "OrderUploadEditDialog",
    validate: (values, { getLocalisationMessage }) => ({
      senderJurisdiction:
        !fp.get(["senderJurisdiction", "id"], values) &&
        getLocalisationMessage("this_field_is_required"),
      senderName:
        !values.senderName && getLocalisationMessage("this_field_is_required"),
      senderPostcode:
        !values.senderPostcode &&
        getLocalisationMessage("this_field_is_required"),
      recipientPostcode:
        !values.recipientPostcode &&
        getLocalisationMessage("this_field_is_required"),
      recipientJurisdiction:
        !fp.get(["recipientJurisdiction", "id"], values) &&
        getLocalisationMessage("this_field_is_required"),
      recipientName:
        !values.recipientName &&
        getLocalisationMessage("this_field_is_required"),
      paymentType:
        !values.paymentType && getLocalisationMessage("this_field_is_required"),
      transportationType:
        !values.transportationType &&
        getLocalisationMessage("this_field_is_required"),
      weight:
        !values.weight && getLocalisationMessage("this_field_is_required"),
      parcelValue:
        fp.toNumber(values.parcelValue) < fp.toNumber(values.codAmount) &&
        getLocalisationMessage(
          "Значение не можеть быть менще наложного платежа",
        ),
      senderPhone:
        !values.senderPhone && getLocalisationMessage("this_field_is_required"),
      senderAddress:
        !values.senderAddress &&
        getLocalisationMessage("this_field_is_required"),
      recipientPhone:
        !values.recipientPhone &&
        getLocalisationMessage("this_field_is_required"),
      recipientAddress:
        !values.recipientAddress &&
        getLocalisationMessage("this_field_is_required"),
    }),
  }),
  mapPropsStream(
    pipeStreams(
      propsStream => {
        const responseStream = propsStream
          .distinctUntilKeyChanged("draftId")
          .switchMap(props =>
            props.getDraftOrder(props.draftId).let(mapObjectResponseStream),
          )
          .distinctUntilChanged(isEqualData);

        return propsStream.combineLatest(responseStream, (props, response) => ({
          ...props,
          draft: response.get("payload"),
          draftLoading: response.get("pending"),
        }));
      },
      propsStream => {
        const onSubmit = (values, dispatch, props) => {
          const draft = props.draft.merge(valuesToDraft(values)).toJS();

          return Promise.resolve(props.actualOnSubmit(draft)).then(
            fp.flow(fp.get("data"), response => {
              if (response.status !== VALID) {
                throw new SubmissionError(mapDraftErrors(response));
              }

              return response;
            }),
          );
        };

        return propsStream.map(props => ({
          ...props,
          actualOnSubmit: props.onSubmit,
          onSubmit: props.onSubmit ? onSubmit : props.onSubmit,
        }));
      },
    ),
  ),
  connect(state => ({
    values: getValues(state),
  })),
  mapPropsStream(propsStream => {
    const packagePricesStream = propsStream
      .map(({ values, customerId }) => {
        if (
          getValue(values, "transportationType") &&
          getValue(values, ["senderJurisdiction", "id"]) &&
          getValue(values, ["recipientJurisdiction", "id"])
        ) {
          return new DataListFilter({
            "dimensions.unit": CM_KG,
            "dimensions.length": 0,
            "dimensions.height": 0,
            "dimensions.weight": _.divide(getValue(values, "weight"), 1000),
            "dimensions.width": 0,
            transportationType: values.transportationType.toUpperCase(),
            fromJurisdictionId: getValue(values, ["senderJurisdiction", "id"]),
            toJurisdictionId: getValue(values, ["recipientJurisdiction", "id"]),
            customerId,
          });
        }
        return null;
      })
      .distinctUntilChanged(isEqualData)
      .withLatestFrom(propsStream)
      .switchMap(([filter, props]) =>
        filter
          ? getCourierPrices(filter).catch(error => {
              props.showErrorMessage(error);
              return Observable.of({});
            })
          : Observable.of({}),
      )
      .startWith({})
      .map(mapResponseListAsList)
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(packagePricesStream, (props, packagePrices) => ({
        ...props,
        packagePrices: packagePrices.getIn(["list"]),
        packagePricesIsLoading: packagePrices.get("pending", false),
      }))
      .distinctUntilChanged(isEqualData);
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .filter(props => props.draft.has("id"))
        .distinctUntilKeyChanged("draft", isEqualData)
        .do(props => {
          const draft = toJS(props.draft);
          const errors = mapDraftErrors(props.draft);
          const values = mapDraftValues(props.draft);
          values.additionalServices = draft.additional_services.map(v => ({
            ...v,
            name: _.get(v, "content"),
          }));
          values.additionalPaymentType =
            draft.payment_type_for_additional_service.code;
          values.paymentType = draft.payment_type.code;
          values.transportationType = draft.transportation_type.code;
          values.recipientJurisdiction = {
            id: draft.recipient_jurisdiction.id,
            name: draft.recipient_jurisdiction.content,
          };
          values.senderJurisdiction = {
            id: draft.sender_jurisdiction.id,
            name: draft.sender_jurisdiction.content,
          };
          values.serviceType = {
            id: draft.service_type.id,
            name: draft.service_type.content,
          };
          values.recipientPostcode = {
            id: draft.recipient_postcode.id,
            name: draft.recipient_postcode.content,
          };
          values.senderPostcode = {
            id: draft.sender_postcode.id,
            name: draft.sender_postcode.content,
          };
          const errorKeys = fp.keys(errors);
          props.initialize(values);
          props.dispatch(reduxActions.stopSubmit(props.form, errors));
          if (errorKeys.length > 0) {
            props.touch(...errorKeys);
          }
        }),
    );

    return propsStream.merge(sideEffectsStream);
  }),
);

const useStyles = makeStyles({
  flexWrapper: {
    "&>div": {
      marginTop: "1rem",
    },
    "&>div:first-child": {
      marginTop: "0 !important",
    },
  },
  cardHeader: {
    padding: 0,
    textAlign: "center",
  },
  content: {
    minWidth: "1200px",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  titleWithBorder2: {
    borderBottom: "1px solid #eeeeee",
  },
  fullWidth: {
    flex: "0 1 auto",
    maxWidth: "68%",
    textTransform: "capitalize",
  },
  price: {
    flex: "0 1 auto",
    paddingLeft: ".5rem",
  },
  dots: {
    clear: "both",
    display: "inline-block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    flex: 1,
    padding: "0 .5rem",
  },
  btn: {
    flex: "1 1 auto",
    margin: "0 .5rem",
  },
  btnWrapper: {
    margin: "0 -.5rem",
  },
});

const OrderUploadEditDialogV2 = ({
  draftId,
  draftLoading,
  getLocalisationMessage,
  handleSubmit,
  onRequestClose,
  packagePrices,
  packagePricesIsLoading,
  submitting,
  change,
  values = {},
  customer,
}) => {
  const classes = useStyles();
  const {
    serviceType,
    senderJurisdiction,
    recipientJurisdiction,
    additionalServices = [],
  } = values;
  const isCustomerCorporate = _.get(customer, "merchant_type") === MERCHANTS;
  const [isOpenServicesDialog, setIsOpenServicesDialog] = useState(false);
  useEffect(() => {
    if (!packagePrices.isEmpty()) {
      const packageName = _.get(serviceType, "name");
      if (packageName) {
        const foundPackageByName = toJS(packagePrices).find(
          v => v.name === packageName,
        );
        if (foundPackageByName) change("serviceType", foundPackageByName);
      }
    }
  }, [packagePrices]);
  const senderJurisdictionId = fp.get("id", senderJurisdiction);
  const recipientJurisdictionId = fp.get("id", recipientJurisdiction);
  return (
    <ModalPaper
      open={true}
      onRequestClose={onRequestClose}
      title={`${getLocalisationMessage(
        "edit_draft",
        "Edit Draft",
      )} #${draftId}`}
    >
      <PageLoading isLoading={submitting || draftLoading} />
      <Dialog
        TransitionComponent={TransitionUp}
        open={isOpenServicesDialog}
        className={classes.content}
        fullWidth={true}
        maxWidth="lg"
        onClose={() => setIsOpenServicesDialog(false)}
      >
        <DialogTitle className={classes.titleWithBorder2}>
          {getLocalisationMessage("add_services", "Add services")}
        </DialogTitle>
        <DialogContent>
          <FlexBox direction="column" flex={true}>
            <FlexBox flex={true} style={{ marginRight: "2rem" }}>
              <SimpleAutocomplete
                isAddedServices={true}
                fullWidth={true}
                chooseData="data.list"
                label={getLocalisationMessage("service")}
                getById={getCachedPostServices}
                chooseAPI={getPostServicesPredictions2}
                filters={{
                  page: 0,
                  size: 20,
                  active: true,
                }}
                renderOption={v =>
                  `${_.get(v, "name")} - ${_.get(v, [
                    showedPrice(isCustomerCorporate),
                  ])}${getLocalisationMessage("som")}`
                }
                onChange={v => {
                  if (
                    v &&
                    v.id &&
                    !additionalServices.some(item => item.id === v.id)
                  ) {
                    change("additionalServices", [
                      ...additionalServices,
                      { ...v, quantity: 1 },
                    ]);
                  }
                }}
              />
            </FlexBox>
            {additionalServices.map(v => (
              <FlexBox
                style={{ marginTop: "1rem" }}
                align={ALIGN_CENTER}
                justify={JUSTIFY_CENTER}
                flex={true}
                key={v.id}
              >
                <span className={classes.fullWidth}>{v.name}</span>
                <span className={classes.dots}>{dots}</span>
                <span className={classes.price}>
                  {money(v[showedPrice(isCustomerCorporate)]) +
                    getLocalisationMessage("som", "so'm")}
                </span>
                <strong>&nbsp; x &nbsp;</strong>
                <IconButton
                  onClick={() =>
                    change(
                      "additionalServices",
                      additionalServices.map(item =>
                        item.id === v.id
                          ? { ...v, quantity: _.get(v, "quantity", 1) + 1 }
                          : item,
                      ),
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Add />
                </IconButton>
                <strong>{_.get(v, "quantity", 1)}</strong>
                <IconButton
                  disabled={_.get(v, "quantity", 1) < 2}
                  onClick={() =>
                    change(
                      "additionalServices",
                      additionalServices.map(item =>
                        item.id === v.id
                          ? { ...v, quantity: _.get(v, "quantity", 1) - 1 }
                          : item,
                      ),
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Remove />
                </IconButton>
                <strong>
                  &nbsp;=&nbsp;
                  {money(
                    _.multiply(
                      v[showedPrice(isCustomerCorporate)],
                      _.get(v, "quantity", 1),
                    ),
                  ) + getLocalisationMessage("som", "so'm")}
                </strong>
                <IconButton
                  onClick={() => {
                    change(
                      "additionalServices",
                      additionalServices.filter(item => item.id !== v.id),
                    );
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Delete />
                </IconButton>
              </FlexBox>
            ))}
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ paddingBottom: "1rem" }}
          >
            <FlexBox className={classes.btnWrapper}>
              <CustomButton
                className={classes.btn}
                onClick={() => setIsOpenServicesDialog(false)}
                startIcon={<KeyboardArrowLeft />}
                variant={OUTLINED}
                color={SECONDARY}
              >
                {getLocalisationMessage("close_window", "Close window")}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </DialogActions>
      </Dialog>
      <FlexBox
        flex="none"
        container={16}
        direction="column"
        element={<form onSubmit={handleSubmit} />}
      >
        <FlexBox container={8} gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                className={classes.cardHeader}
                title={getLocalisationMessage("shipment", "Shipment")}
              />
              <CardContent>
                <FlexBox className={classes.flexWrapper} direction="column">
                  <FormTextField
                    name="barcode"
                    autoFocus={true}
                    autoComplete="new-password"
                    label={getLocalisationMessage("barcode", "Barcode")}
                    fullWidth={true}
                    withSuccessIcon={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <svg style={{ width: "1.5rem", height: "1.5rem" }}>
                            <path
                              fill="currentColor"
                              d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z"
                            />
                          </svg>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormSelectField
                    name="transportationType"
                    fullWidth={true}
                    options={TransportationType}
                    formatOption={x =>
                      getLocalisationMessage(x.toLowerCase(), formatText(x))
                    }
                    label={getLocalisationMessage(
                      "transportation_type",
                      "Transportation Type",
                    )}
                  />
                  <FormSelectField
                    name="serviceType"
                    loading={packagePricesIsLoading}
                    autoWidth={true}
                    fullWidth={true}
                    label={getLocalisationMessage(
                      "service_type",
                      "Service Type",
                    )}
                    formatOption={item => item.get("name")}
                    // compareOptions={isEqualDataIn("id")}
                    compareOptions={isEqualDataIn(["price", "id"])}
                    options={packagePrices}
                    disabled={packagePrices.isEmpty()}
                  />
                  {/* <FormTextField */}
                  {/*  name="referenceId" */}
                  {/*  fullWidth={true} */}
                  {/*  label={getLocalisationMessage( */}
                  {/*    "reference_id", */}
                  {/*    "Reference ID", */}
                  {/*  )} */}
                  {/* /> */}
                  {/* <FormDateField */}
                  {/*  fullWidth={true} */}
                  {/*  name="promisedDate" */}
                  {/*  hintText={getLocalisationMessage( */}
                  {/*    "promised_date", */}
                  {/*    "promised date", */}
                  {/*  )} */}
                  {/*  initialTime={startTime} */}
                  {/* /> */}
                  {/* <CustomButton */}
                  {/*  style={{ */}
                  {/*    marginTop: "1rem", */}
                  {/*    height: "39px", */}
                  {/*  }} */}
                  {/*  fullWidth={true} */}
                  {/*  onClick={() => setIsOpenServicesDialog(true)} */}
                  {/*  variant={OUTLINED} */}
                  {/*  color={SECONDARY} */}
                  {/* > */}
                  {/*  {`${getLocalisationMessage( */}
                  {/*    "add_services", */}
                  {/*    "Add services", */}
                  {/*  )} (${additionalServices.length})`} */}
                  {/* </CustomButton> */}
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                className={classes.cardHeader}
                title={getLocalisationMessage("payments", "Payments")}
              />
              <CardContent>
                <FlexBox className={classes.flexWrapper} direction="column">
                  <FormSelectField
                    name="paymentType"
                    autoWidth={true}
                    fullWidth={true}
                    label={getLocalisationMessage(
                      "payment_type",
                      "Payment Type",
                    )}
                    formatOption={x => getLocalisationMessage(x, formatText(x))}
                    options={OrderPaymentTypes}
                    // disabled={props.paymentTypeOptions.isEmpty()}
                  />
                  <FormTextField
                    name="paidByPostmark"
                    label={getLocalisationMessage(
                      "paid_with_stamps",
                      "Paid with stamps",
                    )}
                    variant={OUTLINED}
                    fullWidth={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getLocalisationMessage("som", "so'm")}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormTextField
                    name="weight"
                    autoWidth={true}
                    fullWidth={true}
                    label={getLocalisationMessage("weight", "Weight")}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {getLocalisationMessage("g", "g")}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <FormTextField */}
                  {/*  type="number" */}
                  {/*  name="codAmount" */}
                  {/*  fullWidth={true} */}
                  {/*  label={getLocalisationMessage("cod_amount", "COD Amount")} */}
                  {/*  InputProps={{ */}
                  {/*    endAdornment: ( */}
                  {/*      <InputAdornment position="end"> */}
                  {/*        {getLocalisationMessage("som", "so'm")} */}
                  {/*      </InputAdornment> */}
                  {/*    ), */}
                  {/*  }} */}
                  {/* /> */}
                  {/* <FormTextField */}
                  {/*  name="parcelValue" */}
                  {/*  fullWidth={true} */}
                  {/*  label={getLocalisationMessage( */}
                  {/*    "shipping_cost", */}
                  {/*    "Shipping cost", */}
                  {/*  )} */}
                  {/*  InputProps={{ */}
                  {/*    endAdornment: ( */}
                  {/*      <InputAdornment position="end"> */}
                  {/*        {getLocalisationMessage("som", "so'm")} */}
                  {/*      </InputAdornment> */}
                  {/*    ), */}
                  {/*  }} */}
                  {/* /> */}
                  {/* <FormSelectField */}
                  {/*  name="additionalPaymentType" */}
                  {/*  autoWidth={true} */}
                  {/*  fullWidth={true} */}
                  {/*  label={getLocalisationMessage("payment_type_for_others")} */}
                  {/*  formatOption={x => getLocalisationMessage(x, formatText(x))} */}
                  {/*  options={OrderPaymentTypes} */}
                  {/* /> */}
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <FlexBox container={8} gutter={8}>
          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                className={classes.cardHeader}
                title={getLocalisationMessage("sender", "Sender")}
              />
              <CardContent>
                <FlexBox className={classes.flexWrapper} direction="column">
                  <FormJMAutoComplete
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{option.name}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {option.value.hierarchy.map((item, index, arr) =>
                            index === arr.length - 1
                              ? item.name
                              : `${item.name} > `,
                          )}
                        </span>
                      </FlexBox>
                    )}
                    fullWidth={true}
                    name="senderJurisdiction"
                    label={getLocalisationMessage(
                      "sender_jurisdiction",
                      "Sender Jurisdiction",
                    )}
                  />
                  <PostcodeAutoCompleteWithJM
                    name="senderPostcode"
                    fullWidth={true}
                    jurisdictionId={senderJurisdictionId}
                    jurisdiction={senderJurisdiction}
                    disabled={!senderJurisdictionId}
                    label={getLocalisationMessage("index", "Index")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                  <FormTextField
                    name="senderName"
                    fullWidth={true}
                    label={getLocalisationMessage("name", "Name")}
                  />

                  <FormTextField
                    name="senderPhone"
                    fullWidth={true}
                    parseOnBlur={parsePhone}
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                  />
                  <FormTextField
                    name="senderAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={getLocalisationMessage("address", "Address")}
                    placeholder="Улица, дом, квартира"
                  />
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>

          <FlexBox flex={true} direction="column">
            <Card>
              <CardHeader
                className={classes.cardHeader}
                title={getLocalisationMessage("recipient", "Recipient")}
              />
              <CardContent>
                <FlexBox className={classes.flexWrapper} direction="column">
                  <FormJMAutoComplete
                    renderOption={option => (
                      <FlexBox direction="column">
                        <span>{option.name}</span>
                        <span
                          style={{
                            fontSize: ".8rem",
                            fontStyle: "italic",
                          }}
                        >
                          {option.value.hierarchy.map((item, index, arr) =>
                            index === arr.length - 1
                              ? item.name
                              : `${item.name} > `,
                          )}
                        </span>
                      </FlexBox>
                    )}
                    fullWidth={true}
                    name="recipientJurisdiction"
                    label={getLocalisationMessage(
                      "recipient_jurisdiction",
                      "Sender Jurisdiction",
                    )}
                  />
                  <PostcodeAutoCompleteWithJM
                    name="recipientPostcode"
                    withSuccessIcon={true}
                    fullWidth={true}
                    jurisdictionId={recipientJurisdictionId}
                    jurisdiction={recipientJurisdiction}
                    disabled={!recipientJurisdictionId}
                    label={getLocalisationMessage("index", "Index")}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                  />
                  <FormTextField
                    name="recipientName"
                    fullWidth={true}
                    label={getLocalisationMessage("name", "Name")}
                  />

                  <FormTextField
                    name="recipientPhone"
                    fullWidth={true}
                    parseOnBlur={formatPhone2}
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                  />
                  <FormTextField
                    name="recipientAddress"
                    fullWidth={true}
                    withSuccessIcon={true}
                    label={getLocalisationMessage("address", "Address")}
                    placeholder="Улица, дом, квартира"
                  />
                </FlexBox>
              </CardContent>
            </Card>
          </FlexBox>
        </FlexBox>

        <Card style={{ margin: ".25rem" }}>
          <FlexBox justify="flex-end">
            <CardActions>
              <Button onClick={onRequestClose}>
                {" "}
                {getLocalisationMessage("dismiss", "Dismiss")}{" "}
              </Button>
              <Button type="submit">
                {" "}
                {getLocalisationMessage("submit", "Submit")}{" "}
              </Button>
            </CardActions>
          </FlexBox>
        </Card>
      </FlexBox>
    </ModalPaper>
  );
};
OrderUploadEditDialogV2.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  draftLoading: PropTypes.bool,
  packagePrices: PropTypes.instanceOf(List),
  packagePricesIsLoading: PropTypes.bool,
  draftId: PropTypes.number.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  values: PropTypes.object,
  change: PropTypes.func,
  customer: PropTypes.object,
};
export default enhancer(OrderUploadEditDialogV2);
