import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { CloudDownload as FileFileDownload } from "@material-ui/icons";
import FormFileField from "../form/FormFileField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { DOWNLOAD_ORDER_CSV_V2_TEMPLATE_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect((state) => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  renderIf("open"),
  reduxForm({
    form: "OrderUploadDialog",
    enableReinitialize: true,
    validate: (values, props) => ({
      file: values.file
        ? values.file.error
        : props.getLocalisationMessage(
            "please_select_file",
            "Please Select File",
          ),
    }),
  }),
);

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

const OrderUploadDialogV2 = ({
  customerId,
  getLocalisationMessage,
  handleSubmit,
  onRequestClose,
  open,
  submitting,
}) => (
  <Dialog fullWidth={true} modal={true} open={open} onClose={onRequestClose}>
    <DialogTitle>
      {" "}
      {getLocalisationMessage("upload_csv_file", "Upload CSV File")}{" "}
    </DialogTitle>
    <DialogContent>
      <PageLoading isLoading={submitting} />
      <FlexBox direction="column">
        <FlexBox gutter={16} style={{ alignItems: "flex-start" }}>
          <FlexBox flex={true}>
            <FormFileField
              name="file"
              fullWidth={true}
              label={getLocalisationMessage("select_file", "Select File")}
              accept={CSV_FILE_TYPES}
            />
          </FlexBox>
          <FlexBox>
            <Button
              target="_blank"
              component="a"
              endIcon={<FileFileDownload />}
              href={updateQuery(DOWNLOAD_ORDER_CSV_V2_TEMPLATE_URL, {
                id: customerId,
              })}
            >
              {getLocalisationMessage("download_template")}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </DialogContent>
    <DialogActions>
      {Boolean(onRequestClose) && (
        <Button onClick={onRequestClose}>
          {" "}
          {getLocalisationMessage("dismiss", "Dismiss")}{" "}
        </Button>
      )}
      <Button onClick={handleSubmit}>
        {" "}
        {getLocalisationMessage("submit", "Submit")}{" "}
      </Button>
    </DialogActions>
  </Dialog>
);
OrderUploadDialogV2.propTypes = {
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  classes: PropTypes.object,
  customerId: PropTypes.number,
};

export default enhancer(OrderUploadDialogV2);
