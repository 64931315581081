import React from "react";
import {
  endOfDay,
  subYears,
  endOfYear,
  subMonths,
  endOfMonth,
  startOfDay,
  startOfYear,
  startOfMonth,
} from "date-fns";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import PropTypes from "prop-types";
import { Fields } from "redux-form";
import { SelectFieldComponent } from "./FormSelectField";
import { isEqualData } from "../../helpers/DataUtils";
import { formatText } from "../../helpers/FormatUtils";

const mergeMeta = (a, b) =>
  fp.keys(a).reduce((acc, key) => {
    acc[key] = a[key] || b[key];

    return acc;
  }, {});

DateRange.propTypes = {
  names: PropTypes.array,

  createOptions: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

DateRange.defaultProps = {
  createOptions: (value, date) =>
    fromJS([
      {
        label: "This_Month",
        value: [startOfMonth(date), endOfMonth(date)],
      },
      {
        label: "Previous_Month",
        value: [
          startOfMonth(subMonths(date, 1)),
          endOfMonth(subMonths(date, 1)),
        ],
      },
      {
        label: "Last_3_Months",
        value: [startOfMonth(subMonths(date, 2)), endOfMonth(date)],
      },
      {
        label: "This_Year",
        value: [startOfYear(date), endOfYear(date)],
      },
      {
        label: "Previous_Year",
        value: [subYears(startOfYear(date), 1), subYears(endOfYear(date), 1)],
      },
      {
        label: "All_Time",
        value: [null, null],
      },
      {
        label: "Custom_Date_Range",
        value,
      },
    ]),
};

function DateRange(props) {
  const start = props[props.names[0]];
  const end = props[props.names[1]];

  const value = fromJS([
    start.input.value ? startOfDay(start.input.value) : null,
    end.input.value ? endOfDay(end.input.value) : null,
  ]);

  const options = props.createOptions(value, new Date());

  const input = {
    value,
    onChange: x => {
      start.input.onChange(x.getIn(["value", 0]));
      end.input.onChange(x.getIn(["value", 1]));
    },
    onFocus: () => {
      start.input.onFocus();
      end.input.onFocus();
    },
    onBlur: () => {
      start.input.onBlur();
      end.input.onBlur();
    },
  };

  const meta = mergeMeta(start.meta, end.meta);

  const childProps = fp.omit([...props.names, "names", "createOptions"], props);

  const compareOptions = (option, x) => isEqualData(option.get("value"), x);

  return (
    <SelectFieldComponent
      {...childProps}
      meta={meta}
      input={input}
      options={options}
      compareOptions={compareOptions}
      formatOption={item =>
        formatText(
          props.getLocalisationMessage(item.get("label"), item.get("label")),
        )
      }
    />
  );
}

FormDateRange.propTypes = {
  fullWidth: PropTypes.bool,

  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,

  createOptions: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

export default function FormDateRange({ startDate, endDate, ...props }) {
  return (
    <Fields {...props} component={DateRange} names={[startDate, endDate]} />
  );
}
