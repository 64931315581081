import { Observable } from "rxjs";
import React from "react";
import { fromJS, Map } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValues, formValueSelector, reduxForm } from "redux-form";
import { Button, Card, CardActions, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import FormCheckbox from "../form/FormCheckbox";
import FormTextField from "../form/FormTextField";
import FormSelectField from "../form/FormSelectField";
import FormGeoAutoComplete from "../form/FormGeoAutoComplete";
import FormCountyPhoneCodeField from "../form/FormCountyPhoneCodeField";
import FormCountryAutoComplete from "../form/FormCountryV2AutoComplete";
import FormYandexGeoAutoComplete from "../form/FormYandexGeoAutoComplete";
import ManualSetOrderLocationDialog from "../form/ManualSetOrderLocationDialog";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import OrderCityField from "../orders-core/OrderCityField";
import OrderNeighborhoodField from "../orders-core/OrderNeighborhoodField";
import { isEqualData } from "../../helpers/DataUtils";
import { createCoordinatesValidator } from "../../helpers/FormUtils";
import {
  findPhoneCodeByCountryName,
  formatText,
  parsePhone,
} from "../../helpers/FormatUtils";
import { validateString } from "../../helpers/ValidateUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getIsRTL, getMessage } from "../../reducers/LocalizationReducer";
import AddressTypes from "../../constants/AddressType";
import { getLocationDetails } from "../../api/v2/admin/AdminOrderApi";
import { responsive } from "../../../shared/theme/jss-responsive";
import { getMapProvider } from "../../../shared/reducers/AppReducer";
import { YANDEX_MAP_PROVIDER } from "../../../shared/constants/MapsControllerConstants";

const valueSelector = formValueSelector("AddressBookCreateForm");
const getValue = (name, formValue) => fp.defaultTo(0, fp.get(name, formValue));
const mapResponseDataMap = fp.flow(
  response => fromJS(response),
  response =>
    fromJS({
      pending: response.get("pending", false),
      data: response.getIn(["payload", "data"]),
    }),
);

const enhancer = compose(
  useSheet({
    map: { [responsive("$xs")]: { display: "none" } },
    autocompleteRtl: {
      "& input": {
        direction: "ltr",
        textAlign: "right",
      },
    },
    autocompleteLtr: {
      "& input": {
        textAlign: "initial",
      },
    },
    spanCode: {
      width: "18%",
      marginBottom: "10px",
      marginRight: "1%",
      fontSize: "16px",
      textAlign: "right",
    },
  }),
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    isRTL: getIsRTL(state),
    mapProvider: getMapProvider(state),
    formValues: valueSelector(state, "country", "phoneCode", "city"),
  })),
  withContext(
    {
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
      getCachedCountry: PropTypes.func.isRequired,
      getCountryPredictions: PropTypes.func.isRequired,
      getCachedPostcode: PropTypes.func.isRequired,
      getPostcodePredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
      getCachedCountry: props.getCachedCountry,
      getCountryPredictions: props.getCountryPredictions,
      getCachedPostcode: props.getCachedPostcode,
      getPostcodePredictions: props.getPostcodePredictions,
    }),
  ),
  reduxForm({
    enableReinitialize: true,
    form: "AddressBookCreateForm",
    validate: (values, props) => ({
      name: validateString(values.name)
        ? props.getLocalisationMessage("name_is_required", "Name is required")
        : null,
      country:
        (!values.country || !values.country.id) &&
        props.getLocalisationMessage(
          "please_select_country",
          "Please select country",
        ),
      // city:
      //   (!values.city||!values.city.id)&&
      //   props.getLocalisationMessage(
      //     "please_select_city",
      //     "Please select city",
      //   ),
      addressType:
        !values.addressType &&
        props.getLocalisationMessage("address_type_required"),
      address:
        !values.address && props.getLocalisationMessage("address_required"),
    }),
  }),
  formValues({ markLocation: "markLocation", country: "country" }),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("initialValues", isEqualData)
      .map(
        fp.flow(fp.update("initialValues", fp.toPlainObject), props => ({
          ...props.initialValues,
        })),
      );

    const locationDetailsStream = propsStream
      .map(props =>
        props.markLocation && props.markLocation.lat && props.markLocation.lng
          ? new DataListFilter({
              lat: props.markLocation.lat,
              lon: props.markLocation.lng,
            })
          : null,
      )
      .distinctUntilChanged(isEqualData)
      .switchMap(filter =>
        filter
          ? getLocationDetails(filter).catch(error => Observable.of({ error }))
          : Observable.of({}),
      )
      .map(mapResponseDataMap)
      .distinctUntilChanged(isEqualData);

    const onSubmit = (
      { markLocation, neighborhood, ...values },
      dispatch,
      props,
    ) =>
      props.actualOnSubmit({
        ...values,
      });

    return propsStream.combineLatest(
      initialValuesStream,
      locationDetailsStream,
      (props, initialValues, locationDetails) => ({
        ...props,
        initialValues,
        actualOnSubmit: props.onSubmit,
        onSubmit: props.onSubmit && onSubmit,
        locationDetails: locationDetails ? locationDetails.get("data") : null,
        locationDetailsPending: locationDetails.get("pending"),
      }),
    );
  }),
  mapPropsStream(propsStream => {
    propsStream
      .map(fp.pick(["change", "locationDetails", "markLocation"]))
      .filter(
        props =>
          Boolean(props.locationDetails) && !props.locationDetails.isEmpty(),
      )
      .distinctUntilKeyChanged("locationDetails")
      .subscribe(({ change, locationDetails, markLocation }) => {
        if (markLocation.street) {
          if (markLocation.street.length > 0) {
            change("street", markLocation.street[0].long_name);
          } else change("street", "");
        }
        if (markLocation.building) {
          if (markLocation.building.length > 0) {
            change("building", markLocation.building[0].long_name);
          } else change("building", "");
        }
        if (locationDetails.getIn(["city", "name"])) {
          change("city", locationDetails.get("city").toJS());
        } else change("city", "");
        // change("pickupContactPhoneCode", getPhoneCode(locationDetails));

        if (locationDetails.getIn(["neighborhood", "name"])) {
          change("neighborhood", locationDetails.get("neighborhood").toJS());
        } else change("neighborhood", "");
      });

    const sideEffectsStream = Observable.merge(
      propsStream
        .take(1)
        .map(fp.pick(["change", "locationDetails"]))
        .filter(
          props =>
            Boolean(props.locationDetails) && !props.locationDetails.isEmpty(),
        )
        .distinctUntilChanged()
        .do(({ change, locationDetails }) =>
          change("city", locationDetails.getIn(["city", "name"])),
        ),
    )
      .switchMapTo(Observable.never())
      .startWith(null);

    return propsStream
      .combineLatest(sideEffectsStream, fp.identity)
      .distinctUntilChanged(isEqualData);
  }),
);

AddressBookFormNew.propTypes = {
  classes: PropTypes.object,

  submitting: PropTypes.bool,
  isRTL: PropTypes.bool,
  isCustomer: PropTypes.bool,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  markLocation: PropTypes.object,
  baseCountry: PropTypes.instanceOf(Map),
  mapProvider: PropTypes.string,
  getLocalisationMessage: PropTypes.func.isRequired,
  values: PropTypes.object,
  locationDetails: PropTypes.instanceOf(Map),
  locationDetailsPending: PropTypes.bool,
};

function AddressBookFormNew(props) {
  const { classes, getLocalisationMessage, isRTL } = props;
  const countryId = getValue("country.id", fp.get("formValues", props));
  const countryCode = getValue(
    "country.description",
    fp.get("formValues", props),
  );
  const isYandex = props.mapProvider === YANDEX_MAP_PROVIDER;

  return (
    <FlexBox
      element={<form onSubmit={props.handleSubmit} />}
      container={8}
      direction="column"
    >
      <FlexBox gutter={8} element={<Card />} direction="column">
        <FlexBox element={<CardContent />} direction="column">
          <PageLoading
            isLoading={props.submitting || props.locationDetailsPending}
          />

          <FlexBox gutter={8}>
            <FlexBox flex={true} direction="column">
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="name"
                    label={`${getLocalisationMessage("name", "Name")} *`}
                    fullWidth={true}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormCountryAutoComplete
                    name="country"
                    fullWidth={true}
                    onChange={v => {
                      props.change("city", null);
                      props.change("neighborhood", null);
                      if (v.id)
                        props.change(
                          "phoneCode",
                          fromJS({ code: findPhoneCodeByCountryName(v.name) }),
                        );
                    }}
                    label={`${getLocalisationMessage("country", "Country")} *`}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  {isYandex ? (
                    <FormYandexGeoAutoComplete
                      fullWidth={true}
                      disabled={!countryId}
                      countryCode={countryCode}
                      name="markLocation"
                      validate={createCoordinatesValidator(
                        getLocalisationMessage(
                          "select_address",
                          "Select Address",
                        ),
                      )}
                      label={`${getLocalisationMessage(
                        "pickup_location",
                        "Pickup Location",
                      )} *`}
                      hintText={getLocalisationMessage(
                        "type_to_search_3_letters",
                        "Type to Search (3 letters minimum)",
                      )}
                    />
                  ) : (
                    <FormGeoAutoComplete
                      name="markLocation"
                      fullWidth={true}
                      disabled={!countryId}
                      countryCode={countryCode}
                      validate={createCoordinatesValidator(
                        getLocalisationMessage(
                          "select_address",
                          "Select Address",
                        ),
                      )}
                      label={`${getLocalisationMessage(
                        "address",
                        "Address",
                      )} *`}
                      hintText={getLocalisationMessage(
                        "type_to_search_3_letters",
                        "Type to Search (3 letters minimum)",
                      )}
                    />
                  )}
                  <ManualSetOrderLocationDialog
                    fieldName="markLocation"
                    change={props.change}
                    disabled={!countryId}
                    location={props.markLocation}
                    countryCode={countryCode}
                  />
                </FlexBox>
              </FlexBox>
              {props.locationDetails && (
                <FlexBox gutter={8}>
                  <FlexBox flex={true}>
                    <OrderCityField
                      readOnly={true}
                      name="city"
                      label={`${getLocalisationMessage("city", "City")}*`}
                      location={props.locationDetails}
                    />
                  </FlexBox>
                  <FlexBox flex={true}>
                    <OrderNeighborhoodField
                      readOnly={true}
                      name="neighborhood"
                      label={getLocalisationMessage("region", "Region")}
                      location={props.locationDetails}
                    />
                  </FlexBox>
                </FlexBox>
              )}
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormCountyPhoneCodeField
                    fullWidth={true}
                    className={
                      isRTL ? classes.autocompleteRtl : classes.autocompleteLtr
                    }
                    name="phoneCode"
                    label={`${getLocalisationMessage(
                      "phone_code",
                      "Phone Code",
                    )} *`}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type to search ...",
                    )}
                  />
                </FlexBox>
                <FlexBox flex={true} align="flex-end" justify="flex-end">
                  <FormTextField
                    fullWidth={true}
                    name="phone"
                    label={getLocalisationMessage(
                      "phone_number",
                      "Phone Number",
                    )}
                    parse={parsePhone}
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="street"
                    fullWidth={true}
                    label={getLocalisationMessage("street", "Street")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="building"
                    fullWidth={true}
                    label={getLocalisationMessage("building", "Building")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="apartment"
                    fullWidth={true}
                    label={getLocalisationMessage("apartment", "Apartment")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormTextField
                    name="floor"
                    fullWidth={true}
                    label={getLocalisationMessage("floor", "Floor")}
                  />
                </FlexBox>
              </FlexBox>

              <FlexBox gutter={8}>
                <FlexBox flex={true}>
                  <FormTextField
                    name="landmark"
                    fullWidth={true}
                    label={getLocalisationMessage("landmark", "Landmark")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormSelectField
                    name="addressType"
                    label={`${getLocalisationMessage(
                      "address_type",
                      "Address Type",
                    )} *`}
                    fullWidth={true}
                    options={AddressTypes}
                    formatOption={value =>
                      formatText(getLocalisationMessage(value, value))
                    }
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox element={<CardActions />}>
          {props.isCustomer && (
            <FlexBox flex={true} justify="column">
              <FlexBox flex={true}>
                <FormCheckbox
                  name="defaultAddress"
                  label={getLocalisationMessage(
                    "default_address",
                    "Default Address",
                  )}
                />
              </FlexBox>
            </FlexBox>
          )}
          <FlexBox flex={true} justify="column">
            <FlexBox flex={true}>
              <Button variant="text" type="submit">
                {getLocalisationMessage("save", "Save")}
              </Button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default enhancer(AddressBookFormNew);
