import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import { Dialog, Button, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { CloudDownload } from "@material-ui/icons";
import FormCheckbox from "../form/FormCheckbox";
import FormFileField from "../form/FormFileField";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { DOMESTIC, INTERNATIONAL } from "../../constants/OrderCSVTemplateTypes";
import { updateQuery } from "../../../shared/helpers/UrlUtils";
import { DOWNLOAD_ORDER_CSV_TEMPLATE_URL } from "../../../shared/constants/FileProxyControllerConstants";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  renderIf("open"),
  reduxForm({
    form: "OrderUploadDialog",
    enableReinitialize: true,
    initialValues: { isDomestic: true, isInternational: false },
    validate: (values, props) => ({
      file: values.file
        ? values.file.error
        : props.getLocalisationMessage(
            "please_select_file",
            "Please Select File",
          ),
    }),
  }),
  formValues("isDomestic", "isInternational"),
);

const CSV_FILE_TYPES = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
].join(",");

const DOMESTIC_CSV_TEMPLATE = updateQuery(DOWNLOAD_ORDER_CSV_TEMPLATE_URL, {
  fileType: DOMESTIC,
});

const INTERNATIONAL_CSV_TEMPLATE = updateQuery(
  DOWNLOAD_ORDER_CSV_TEMPLATE_URL,
  { fileType: INTERNATIONAL },
);

OrderUploadDialog.propTypes = {
  isDomestic: PropTypes.bool,
  isInternational: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,

  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,

  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitFail: PropTypes.func,
  change: PropTypes.func,

  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderUploadDialog(props) {
  const { getLocalisationMessage } = props;
  return (
    <Dialog
      modal={true}
      title={getLocalisationMessage("upload_csv_file", "Upload CSV File")}
      open={props.open}
      bodyStyle={{ overflowX: "hidden" }}
      onRequestClose={props.onRequestClose}
      actions={
        <div>
          {Boolean(props.onRequestClose) && (
            <Button onClick={props.onRequestClose}>
              {getLocalisationMessage("dismiss", "Dismiss")}
            </Button>
          )}

          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      }
    >
      <PageLoading isLoading={props.submitting} />

      <FlexBox container={16} direction="column">
        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            <FormFileField
              name="file"
              fullWidth={true}
              hintText={getLocalisationMessage("select_file", "Select File")}
              accept={CSV_FILE_TYPES}
            />
          </FlexBox>

          <FlexBox align="center">
            <IconButton
              target="_blank"
              containerElement="a"
              tooltip={getLocalisationMessage(
                "download_template",
                "Download Template",
              )}
              href={
                props.isDomestic
                  ? DOMESTIC_CSV_TEMPLATE
                  : INTERNATIONAL_CSV_TEMPLATE
              }
            >
              <CloudDownload />
            </IconButton>
          </FlexBox>
        </FlexBox>

        <br />

        <FlexBox gutter={8}>
          <FlexBox flex={true}>
            <FormCheckbox
              name="isDomestic"
              onClick={() =>
                props.change("isInternational", !props.isInternational)
              }
              label={getLocalisationMessage(
                "domestic_orders",
                "Domestic Orders",
              )}
            />
            <FormCheckbox
              name="isInternational"
              onClick={() => props.change("isDomestic", !props.isDomestic)}
              label={getLocalisationMessage(
                "international_orders",
                "International Orders",
              )}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Dialog>
  );
}

export default enhancer(OrderUploadDialog);
