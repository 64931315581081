import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import Ok from "./assets/ok.png";
import Bad from "./assets/bad.png";
import Good from "./assets/good.png";
import Amazing from "./assets/amazing.png";
import Terrible from "./assets/terrible.png";

const labels = ["terrible", "bad", "ok_ok", "very_good", "amazing"];
const icons = [Terrible, Bad, Ok, Good, Amazing];

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    root: {
      color: "#fff",
      padding: "12px 0",
      textAlign: "center",
      fontFamily: "Blogger Sans",
      transition: "ease .2s all",
    },

    "emotion-1": {
      backgroundColor: "#d32f2f",
      "& > $icon": { backgroundImage: `url(${icons[0]})` },
    },
    "emotion-2": {
      backgroundColor: "#ff9800",
      "& > $icon": { backgroundImage: `url(${icons[1]})` },
    },
    "emotion-3": {
      backgroundColor: "#c0ca33",
      "& > $icon": { backgroundImage: `url(${icons[2]})` },
    },
    "emotion-4": {
      backgroundColor: "#4caf50",
      "& > $icon": { backgroundImage: `url(${icons[3]})` },
    },
    "emotion-5": {
      backgroundColor: "#00bcd4",
      "& > $icon": { backgroundImage: `url(${icons[4]})` },
    },

    title: { display: "block", fontSize: "16px" },
    label: { display: "block", fontSize: "18px", textTransform: "uppercase" },

    icon: {
      width: "100%",
      height: "180px",
      margin: "10px 0",
      backgroundSize: "40%",
      backgroundPosition: "50%",
      transition: "ease .2s all",
      backgroundRepeat: "no-repeat",
    },
  }),
);

FeedbackEmotions.propTypes = {
  classes: PropTypes.object,
  emotion: PropTypes.number,
  getLocalisationMessage: PropTypes.func,
};

function FeedbackEmotions(props) {
  const { classes } = props;

  return (
    <div className={cx(classes.root, classes[`emotion-${props.emotion}`])}>
      <div className={classes.title}>
        {props.getLocalisationMessage(
          "how_was_your_experience_with_us",
          "How was your experience with us?",
        )}
      </div>

      <div className={classes.icon} />

      <div className={classes.label}>
        {props.getLocalisationMessage(
          labels[props.emotion - 1],
          formatText(labels[props.emotion - 1]),
        )}
      </div>
    </div>
  );
}

export default enhancer(FeedbackEmotions);
