import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import FormCityAutoComplete from "../form/FormCityV2AutoComplete";

const enhancer = compose(
  useSheet({
    root: {
      width: "100%",
      paddingRight: 5,
    },
  }),
);

OrderCityFieldDefault.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  classes: PropTypes.object,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  countryId: PropTypes.number.isRequired,
};

function OrderCityFieldDefault(props) {
  const { classes, countryId } = props;

  return (
    <div className={classes.root}>
      <FormCityAutoComplete
        name={props.name}
        countryId={countryId}
        fullWidth={true}
        // readOnly={props.readOnly}
        onClick={() => props.onClick && props.onClick(props.readOnly)}
        hintText={props.label}
        label={props.label}
      />
    </div>
  );
}

export default enhancer(OrderCityFieldDefault);
