import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const TERMS_SETTINGS_URL = `${API_ROOT_URL}/terms`;
const TERMS_SETTINGS_LIST_URL = `${API_ROOT_URL}/terms`;
const TERMS_SETTINGS_DEFAULT_TERMS_URL = `${API_ROOT_URL}/default-terms`;
const TERMS_SETTINGS_ITEM_URL = `${API_ROOT_URL}/terms/%s`;

// default terms

const DEFAULT_PUBLIC_TERMS_URL = `${API_ROOT_URL}/default-public-terms`;
const DEFAULT_PUBLIC_PROHIBITS_URL = `${API_ROOT_URL}/default-prohibits-terms`;
const DEFAULT_COD_TERMS_URL = `${API_ROOT_URL}/default-cod-terms`;

// customer terms

const CUSTOMER_PUBLIC_TERMS_URL = `${API_ROOT_URL}/customer-public-terms`;
const CUSTOMER_PUBLIC_PROHIBITS_URL = `${
  API_ROOT_URL
}/customer-prohibits-terms`;
const CUSTOMER_COD_TERMS_URL = `${API_ROOT_URL}/customer-cod-terms`;

export const getTermsSettingsDefaultTerms = (request: DataListFilter) =>
  api.getStream(TERMS_SETTINGS_DEFAULT_TERMS_URL, {
    params: request.getDefinedValues(),
  });

export const getTermsSettingsList = (request: DataListFilter) =>
  api.getStream(TERMS_SETTINGS_LIST_URL, {
    params: request.getDefinedValues(),
  });

export const createTermsSettings = body =>
  api.post(TERMS_SETTINGS_URL, { body });

export const updateTermsSettings = body =>
  api.put(TERMS_SETTINGS_URL, { body });

export const deleteTermsSettings = id =>
  api.delete(sprintf(TERMS_SETTINGS_ITEM_URL, id));

export const getTermsSettingsItem = id =>
  api.getStream(sprintf(TERMS_SETTINGS_ITEM_URL, id));

export const getDefaultPublicTerms = () =>
  api.getStream(DEFAULT_PUBLIC_TERMS_URL);

export const getDefaultPublicProhibits = () =>
  api.getStream(DEFAULT_PUBLIC_PROHIBITS_URL);

export const getDefaultCodTerms = () => api.getStream(DEFAULT_COD_TERMS_URL);

export const getCustomerPublicTerms = () =>
  api.getStream(CUSTOMER_PUBLIC_TERMS_URL);

export const getCustomerPublicProhibits = () =>
  api.getStream(CUSTOMER_PUBLIC_PROHIBITS_URL);

export const getCustomerCodTerms = () => api.getStream(CUSTOMER_COD_TERMS_URL);
