import { Observable } from "rxjs";
import React, { useEffect, useState } from "react";
import { fromJS, List, Map } from "immutable";
import fp from "lodash/fp";
import { compose, createEventHandler, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import DataList, { DataListColumn } from "../data-list/DataList";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { formatNumber } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { reduxForm } from "redux-form";
import CustomButton, { OUTLINED } from "../ui-core/CustomButton";
import { CheckCircle, Error } from "@material-ui/icons";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return { getLocalisationMessage };
  }),
  renderIf("open"),
  mapPropsStream(propsStream => {
    const {
      handler: onRequestRefresh,
      stream: onRequestRefreshStream,
    } = createEventHandler();

    const progressResponseStream = propsStream
      .distinctUntilKeyChanged("batchId")
      .switchMap(props =>
        props
          .getOrderUploadPublishProgress(props.batchId)
          .catch(error => Observable.of({ error }))
          .repeatWhen(() => onRequestRefreshStream),
      )
      .filter(v => !v.pending)
      .map(
        fp.flow(
          response => fromJS(response),
          response =>
            fromJS({
              pending: response.get("pending"),
              data: response.getIn(["payload", "data"], Map()),
              failedOrders: response.getIn(
                ["payload", "data", "failed_orders"],
                List(),
              ),
            }),
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream.combineLatest(
      progressResponseStream,
      (props, progressResponse) => ({
        ...props,
        onRequestRefresh,
        data: progressResponse.get("data"),
        pendingCount: progressResponse.getIn(["data", "pending"]),
        failedOrders: progressResponse.get("failedOrders"),
        isLoading: progressResponse.get("pending"),
      }),
    );
  }),
  reduxForm({ form: "OrderUploadPublishDialog" }),
);

OrderUploadPublishDialog.propTypes = {
  submitting: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  failedOrders: PropTypes.instanceOf(Map),
  data: PropTypes.object,
  onRequestRefresh: PropTypes.func,
};

function OrderUploadPublishDialog(props) {
  const { failedOrders, getLocalisationMessage, isLoading, data } = props;

  const [createdState, setCreatedState] = useState(0);
  const [failedState, setFailedState] = useState(0);

  useEffect(
    () =>
      data.get("created") &&
      data.get("created") !== createdState &&
      setCreatedState(data.get("created")),
    [data.get("created")],
  );

  useEffect(
    () =>
      data.get("failed") &&
      data.get("failed") !== failedState &&
      setFailedState(data.get("failed")),
    [data.get("failed")],
  );

  let refreshList;
  useEffect(() => {
    if (!isLoading && createdState + failedState !== data.get("total"))
      refreshList = setInterval(() => props.onRequestRefresh(), 3000);
    return () => clearInterval(refreshList);
  }, [isLoading, createdState, createdState, data.get("total")]);
  return (
    <Dialog open={props.open} onClose={props.onRequestClose}>
      <PageLoading isLoading={props.submitting || props.isLoading} />
      <DialogContent style={{ minWidth: 600 }}>
        <FlexBox container={8} direction="column">
          <FlexBox gutter={8} justify="space-between">
            <FlexBox flex={true}>
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage("total", "Total")}
                secondary={formatNumber(data.get("total"))}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage("pending", "Pending")}
                secondary={formatNumber(data.get("pending"))}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage("failed", "Failed")}
                secondary={formatNumber(data.get("failed"))}
              />
            </FlexBox>

            <FlexBox flex={true}>
              <ListItemText
                disabled={true}
                primary={getLocalisationMessage("created", "Created")}
                secondary={formatNumber(data.get("created"))}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>

        <FlexBox flex={true} justify={JUSTIFY_CENTER}>
          {data.get("pending") === 0 ? (
            failedOrders.size > 0 ? (
              <Error style={{ color: "red" }} fontSize="large" />
            ) : (
              <CheckCircle fontSize="large" color="secondary" />
            )
          ) : (
            <CircularProgress size="2rem" color="secondary" />
          )}
        </FlexBox>
        <LinearProgress
          color="secondary"
          style={{
            margin: ".25rem",
          }}
          variant="determinate"
          value={(data.get("created", 0) / data.get("total")) * 100}
        />

        <FlexBox
          direction="column"
          style={{
            transition: "all .5s ease",
            overflow: "hidden",
            height: failedOrders.size > 0 ? "100%" : "0",
            opacity: failedOrders.size > 0 ? 1 : 0,
          }}
        >
          <h6 style={{ textAlign: "center", marginTop: ".5rem" }}>
            {getLocalisationMessage("failed_orders", "failed orders")}
          </h6>
          <DataList
            isLoading={isLoading}
            totalCount={failedOrders.size}
            list={failedOrders}
            rowCount={failedOrders.size}
            overscanRowCount={9}
            rowGetter={options => failedOrders.get(options.index)}
          >
            <DataListColumn
              width={104}
              label={getLocalisationMessage("id", "Id")}
              dataKey="id"
              cellRenderer={row => row.cellData.get("id")}
            />
            <DataListColumn
              width={104}
              label={getLocalisationMessage("reason", "Reason")}
              dataKey="reason"
              disableSort={true}
              cellRenderer={row =>
                row.cellData.get("reason", getLocalisationMessage("na", "N/A"))
              }
            />
          </DataList>
        </FlexBox>
      </DialogContent>
      <DialogActions>
        <FlexBox flex={true} justify={JUSTIFY_CENTER}>
          <CustomButton
            color="secondary"
            variant={OUTLINED}
            onClick={props.onRequestClose}
          >
            {getLocalisationMessage("dismiss", "Dismiss")}
          </CustomButton>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(OrderUploadPublishDialog);
