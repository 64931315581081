import React from "react";
import _ from "lodash";
import { Map } from "immutable";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavTabs from "../ui-core/NavTabs";
import TabBadge from "../deprecated/TabBadge";
import { formatText } from "../../helpers/FormatUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import CODTransferStatus from "../../constants/CODTransferStatus";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);
CODTransferTableTabs.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  statusCounts: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function CODTransferTableTabs({
  statusCounts,
  location,
  getLocalisationMessage,
}) {
  const query = _.omit(location.query, ["page"]);
  const value = CODTransferStatus.has(query.status)
    ? query.status
    : CODTransferStatus.first();

  return (
    <NavTabs
      width={540}
      value={value}
      tabs={CODTransferStatus.map(type => ({
        to: { pathname: location.pathname, query: { ...query, status: type } },
        value: type,
        label: (
          <TabBadge
            label={getLocalisationMessage(type, formatText(type))}
            badge={statusCounts && statusCounts.get(type)}
          />
        ),
      })).toArray()}
    />
  );
}

export default enhancer(CODTransferTableTabs);
