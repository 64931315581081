import { Observable } from "rxjs";
import sprintf from "sprintf";
import { api } from "../shared/BaseApi";
import {
  API_ROOT_URL,
  API_V2_ROOT_URL,
} from "../../../shared/constants/ApiConstants";

const PUBLIC_ORDER_URL = `${API_ROOT_URL}/public/order/%s`;
const DELIVERY_CONFIRM_ORDER_URL = `${
  API_ROOT_URL
}/public/delivery-confirmation/confirm`;
const PUBLIC_ORDER_NOTES_URL = `${API_ROOT_URL}/public/order/%s/notes`;
const PUBLIC_ORDER_HISTORY_URL = `${
  API_ROOT_URL
}/public/order/%s/history_items`;
const PUBLIC_ORDER_DROPOFF_LOCATION_URL = `${
  API_ROOT_URL
}/public/recipient/order/%s/dropofflocation`;
const PUBLIC_ORDER_PICKUP_LOCATION_URL = `${
  API_ROOT_URL
}/public/sender/order/%s/pickuplocation`;

const PUBLIC_ORDER_DRIVER_URL = `${API_ROOT_URL}/public/order/%s/driver`;
const PUBLIC_ORDER_DRIVER_LOCATION_URL = `${
  API_ROOT_URL
}/public/order/%s/driver/location`;

const GET_LOCATIONS_BY_ADDRESS = `${API_ROOT_URL}/coordinate_by_address`;
const GET_LOCATIONS_BY_ADDRESS_V2 = `${API_V2_ROOT_URL}/coordinate_by_address`;

const MAX_TIMEOUT = 30 * 1000;

export type PublicOrderLocation = {
  lat: number,
  lon: number,
  city: string,
  pickup: boolean,
  details: string,
  address: string,
  contact_name: string,
  email: string,
  phone: string,
  company_name: string,
  confirmed_by_recipient: boolean,
  address_type: string,
  postcode: string,
  address_city: string,
  address_street: string,
};

export const updatePublicOrderDropoffLocation = (
  orderNumber,
  body: PublicOrderLocation,
) => api.put(sprintf(PUBLIC_ORDER_DROPOFF_LOCATION_URL, orderNumber), { body });

export const updatePublicOrderPickupLocation = (
  orderNumber,
  body: PublicOrderLocation,
) => api.put(sprintf(PUBLIC_ORDER_PICKUP_LOCATION_URL, orderNumber), { body });

export const getPublicOrder = (id: number, params = {}) =>
  api.getStream(sprintf(PUBLIC_ORDER_URL, id), {
    params,
    timeout: MAX_TIMEOUT,
  });

export const orderDeliveryConfirmPublicOrder = body =>
  api.post(DELIVERY_CONFIRM_ORDER_URL, { body });

export const getPublicOrderActivities = id =>
  Observable.combineLatest(
    api.getStream(sprintf(PUBLIC_ORDER_NOTES_URL, id), {
      timeout: MAX_TIMEOUT,
    }),
    api.getStream(sprintf(PUBLIC_ORDER_HISTORY_URL, id), {
      timeout: MAX_TIMEOUT,
    }),
    (notes, history) => ({
      pending: notes.pending || history.pending,
      progress: (notes.progress + history.progress) / 2,
      payload: { notes: notes.payload, history: history.payload },
    }),
  );

export const getPublicOrderDriver = (id: number) =>
  api.getStream(sprintf(PUBLIC_ORDER_DRIVER_URL, id), { timeout: MAX_TIMEOUT });

export const getPublicOrderDriverLocation = (id: number) =>
  api.getStream(sprintf(PUBLIC_ORDER_DRIVER_LOCATION_URL, id), {
    timeout: MAX_TIMEOUT,
  });

export const getAddressLocationByAddress = values =>
  api.get(GET_LOCATIONS_BY_ADDRESS, { params: values });
export const getAddressLocationByAddressV2 = values =>
  api.get(GET_LOCATIONS_BY_ADDRESS_V2, { params: values });
