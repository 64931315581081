import React from "react";
import { Map, Set, List } from "immutable";
import fp from "lodash/fp";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PriceWrapper from "../ui-core/PriceWrapper";
import DataList, {
  DataListColumn,
  DataListCheckbox,
} from "../data-list/DataList";
import DateTimeCell from "../data-list/DateTimeCell";
import { formatText } from "../../helpers/FormatUtils";
import {
  formatCODStatusCode,
  formatMerchantTypeI18n,
  formatCODStatusCodeLocalisedi18n,
} from "../../helpers/OrderHelper";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessages } from "../../reducers/LocalizationReducer";
import TextWithLink from "../../../client/components/router/TextWithLink";

const enhancer = compose(
  connect(state => ({
    i18n: getMessages(state),
  })),
);

CODWithdrawOrderList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  list: PropTypes.instanceOf(List).isRequired,
  maxSearchItems: PropTypes.number,
  altHeader: PropTypes.node,
  selectable: PropTypes.bool,
  onRowSelect: PropTypes.func,
  selectedNumbers: PropTypes.instanceOf(Set),
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  createOrderHref: PropTypes.func,
  createCustomerHref: PropTypes.func,
  i18n: PropTypes.instanceOf(Map),
  statusMessages: PropTypes.instanceOf(Map),
};

CODWithdrawOrderList.defaultProps = {
  maxSearchItems: 0,
  createCustomerHref: fp.noop,
};

function CODWithdrawOrderList(props) {
  const { i18n } = props;
  const currentNumbers = props.selectable
    ? props.list.map(item => item.get("order_number")).toSet()
    : Set();

  return (
    <DataList
      filter={props.filter}
      onFilterChange={props.onFilterChange}
      maxSearchItems={props.maxSearchItems}
      totalCount={props.totalCount}
      rowCount={props.list.size}
      isLoading={props.isLoading}
      overscanRowCount={10}
      maxPageSize={Infinity}
      rowGetter={options => props.list.get(options.index)}
      altHeader={props.altHeader}
      selectedRowCount={props.selectable ? props.selectedNumbers.size : 0}
    >
      {props.selectable && (
        <DataListCheckbox
          allRowsSelected={() =>
            currentNumbers.size > 0 &&
            props.selectedNumbers.isSuperset(currentNumbers)
          }
          onAllRowsSelect={value =>
            props.onRowSelect(
              value
                ? props.selectedNumbers.union(currentNumbers)
                : props.selectedNumbers.subtract(currentNumbers),
            )
          }
          rowSelected={row =>
            props.selectedNumbers.has(row.cellData.get("order_number"))
          }
          onRowSelect={row =>
            props.onRowSelect(
              row.selected
                ? props.selectedNumbers.add(row.cellData.get("order_number"))
                : props.selectedNumbers.delete(
                    row.cellData.get("order_number"),
                  ),
            )
          }
        />
      )}
      <DataListColumn
        width={104}
        disableSort={true}
        label={i18n.get("order_number", "Order Number")}
        dataKey="order_number"
        justifyContent="end"
        cellRenderer={row => (
          <strong>
            <TextWithLink
              to={props.createOrderHref(row.cellData.get("order_id"))}
            >
              {row.cellData.get("order_number")}
            </TextWithLink>
          </strong>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={i18n.get("completed_date", "Completed Date")}
        dataKey="completed_date"
        justifyContent="center"
        cellRenderer={row => (
          <DateTimeCell date={row.cellData.get("completed_date")} />
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={i18n.get("customer", "Customer")}
        dataKey="customer_name"
        justifyContent="center"
        cellRenderer={row => (
          <TextWithLink
            to={props.createCustomerHref(
              row.cellData.getIn(["customer", "id"]),
            )}
          >
            {row.cellData.getIn(["customer", "name"])}
          </TextWithLink>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={i18n.get("merchant_type", "Merchant Type")}
        dataKey="status"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {formatMerchantTypeI18n(row.cellData.get("merchant_type"), i18n)}
          </div>
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={i18n.get("payer", "Payer")}
        dataKey="payer"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {i18n.get(
              row.cellData.get("payer"),
              formatText(row.cellData.get("payer")),
            )}
          </div>
        )}
      />
      <DataListColumn
        width={64}
        disableSort={true}
        label={i18n.get("dc", "DC")}
        dataKey="dc"
        justifyContent="end"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("service_charge")}
            withCode={false}
          />
        )}
      />
      <DataListColumn
        width={64}
        disableSort={true}
        label={i18n.get("cod", "COD")}
        dataKey="cod"
        justifyContent="end"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("cod_charge")}
            withCode={false}
          />
        )}
      />
      <DataListColumn
        width={64}
        disableSort={true}
        label={i18n.get("net_to_customer", "Net To Customer")}
        dataKey="net_to_customer"
        justifyContent="end"
        cellRenderer={row => (
          <PriceWrapper
            price={row.cellData.get("net_to_customer")}
            withCode={false}
          />
        )}
      />
      <DataListColumn
        width={96}
        disableSort={true}
        label={i18n.get("status", "Status")}
        dataKey="status"
        justifyContent="center"
        cellRenderer={row => (
          <div>
            {formatCODStatusCodeLocalisedi18n(
              row.cellData.get("cod_status"),
              i18n,
              i18n.get("na"),
            ) || formatCODStatusCode(row.cellData.get("cod_status"))}
          </div>
        )}
      />
    </DataList>
  );
}

export default enhancer(CODWithdrawOrderList);
